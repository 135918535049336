import _ from "lodash";

// Validation
export default async function Validate(page, trigger, watch) {
  if (page === 2) {
    const { defaultCountry = {} } = watch();
    const isTeleValid =
      (watch("mobil").length > 4 ||
        watch("privat").length > 4 ||
        watch("geschaeft").length > 4 ||
        watch("weitereTelefonnummer").length > 4 ||
        watch("telephoneNumber").length > 4) &&
      !_.isEqual(watch("PersonWeitereTelefonnummer"), "defaultTelephone");
    let listTrigger = [
      "anrede",
      "vorname",
      "nachname",
      "zivilstand",
      "land",
      "nationalitaet",
      "geburtstag",
      "strasse",
      "nummer",
      "plz",
      "ort",
      "wohnhaftSeit",
      "telephoneNumber",
      "email",
      "beneficial",
      "PersonWeitereTelefonnummer",
    ];
    if (!_.isEqual(watch("nationalitaet"), defaultCountry)) {
      listTrigger.push("residencePermit");
      if (_.isEqual(watch("residencePermit").expirationDate, true)) {
        listTrigger.push("residencePermitDate");
      }
    }
    if (watch("beneficial") === "Ja") {
      listTrigger = listTrigger.concat([
        "salutationKInh",
        "firstnameKInh",
        "lastnameKInh",
        "countryKInh",
        "nationalityKInh",
        "birthdayKInh",
        "streetKInh",
        "numberKInh",
        "postcodeKInh",
        "placeKInh",
        "emailKInh",
        "telephoneNumberKInh",
      ]);
      if (!_.isEqual(watch("nationalityKInh"), defaultCountry)) {
        listTrigger.push("residencePermitKInh");
        if (_.isEqual(watch("residencePermitKInh").expirationDate, true)) {
          listTrigger.push("residencePermitDateKInh");
        }
      }
      if (watch("naturalPerson") !== "Ja") {
        listTrigger = listTrigger.concat([
          "companynamejurP",
          "streetjurP",
          "numberjurP",
          "postcodejurP",
          "placejurP",
          "landKInh",
        ]);
      }
    }
    const triggerRS = await trigger(listTrigger, { shouldFocus: false });
    if (triggerRS && isTeleValid) {
      return true;
    } else {
      return false;
    }
  }
  
  if (page === 3) {
    let listTrigger = [
      "arbeitgeber",
      "strasseArbeitgeber",
      "hausnummerArbeitgeber",
      "PLZArbeitgeber",
      "ortArbeitgeber",
      "anstellungsverhaeltnis",
      "angestelltSeit",
      "monatlichesNettoEinkommen",
      "nebenerwerbChecked",
      "landArbeitgeber",
    ];
    if (watch("nebenerwerbChecked") === "Ja") {
      listTrigger.push("nebenerwerb");
    }
    if (watch("anstellungsverhaeltnis") === "Ohne Anstellungsverhältnis") {
      listTrigger.push("vermoegen");
    }
    if (watch("anstellungsverhaeltnis") === "temporär") {
      listTrigger.push("angestelltBis");
    }
    const triggerRS = await trigger(listTrigger, { shouldFocus: false });
    if (triggerRS) {
      return true;
    } else {
      return false;
    }
  }

  if (page === 4) {
    let listTrigger = [
      "wohnsituation",
      "kinderHaushalt",
      "wohnMietkosten",
      "Bemerkungen",
      "livingConfirm",
      "livingAgree",
      "andereRegelAuslagen",
      "VertragersatzChecked",
      "Betreibungen",
      "livingContract",
    ];

    if (watch("VertragersatzChecked") === "Ja") {
      listTrigger.push("Vertragersatz");
    }
    if (watch("wohnMietkosten") === "0") {
      listTrigger.push("BegruendungWohnen");
    }
    const triggerRS = await trigger(listTrigger, { shouldFocus: false });
    if (triggerRS && watch("wohnMietkosten") !== "") {
      return true;
    } else {
      return false;
    }
  }

  return true;
}
