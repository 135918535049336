import React from "react";
import ButtonDirection from "./ButtonDirection";
import MainInfo from "./MainInfo";
import ShortInfo from "./ShortInfo";
import Button from "../ButtonV2/ButtonV2";
import "./cta.css";
import { useIntl } from "react-intl";
import { useLocale } from "context/locale";
import { getLink } from "screen/LeasingFormPublic/links-configuration";
import { getGridSystemForModalCta } from "screen/LeasingFormPublic/leasing-engine";
import useWindowSize from "hooks/useWindowSize";

function ModalCta({
  value,
  func,
  model,
  price,
  leasePrice,
  subTitle,
  nomInterest,
  effInterest,
  currency,
  border,
  prePayment,
  currMonth,
  currKm,
  srcImg,
  currResidual,
  sendEmail,
  printOffer,
  isOpenModalCta,
  setIsOpenModalCta,
  showTeslaConfigurator,
}) {
  const { formatMessage } = useIntl();
  const { locale } = useLocale();
  const [width, height] = useWindowSize();

  const ModalWithOutImage = (
    <div
      className="modal-sticky-wrap"
      style={{ gridTemplateColumns: getGridSystemForModalCta(width) }}>
      <div className="space-modal-cta"></div>
      <div className="col-first-modal-cta for-button">
        <div
          className="btn-modal-wrapper"
          onClick={() => {
            func(!value);
            setIsOpenModalCta(!isOpenModalCta);
            document.body.style.overflow = "";
          }}>
          <ButtonDirection direction="down" closeButton />
        </div>
      </div>
      <div className="space-modal-cta"></div>
      <div className="visiable-portrait">
        <div className="col-second-modal-cta for-short-info">
          <h2 className="mb-3 font-weight-bold ">
            {formatMessage({ id: "publicleasing.title.deal" })}
          </h2>
          <div className="visiable-mobile">
            <MainInfo
              model={model}
              price={price}
              prePayment={prePayment}
              currMonth={currMonth}
              currKm={currKm}
              currency={currency}
              currResidual={currResidual}
            />
          </div>

          <div className="short-info-wrapper-mobile">
            <ShortInfo
              price={price}
              leasePrice={leasePrice}
              subTitle={subTitle}
              nomInterest={nomInterest}
              effInterest={effInterest}
              border={true}
              currency={currency}
            />
          </div>
          <div className="mb-3">
            <h4 className="font-weight-bold question-text-wrapper">
              {formatMessage({
                id: "publicleasing.title.next-step",
              })}
            </h4>
          </div>
          <div className="mb-3 flow3-btn">
            {showTeslaConfigurator && (
              <div className="mr-3">
                <Button
                  title={formatMessage({
                    id: "publicleasing.button.configure-tesla",
                  })}
                  type="primary"
                  onClick={() => {
                    window.open(
                      getLink(locale, "TESTLA_CONFIGURATOR"),
                      "_blank"
                    );
                  }}
                />
              </div>
            )}
            <div className="ltq-config-modal">
              <Button
                title={formatMessage({
                  id: "publicleasing.button.contact-us",
                })}
                type="secondary"
                onClick={printOffer}
              />
            </div>
            <div className="right-disclaimer-mobile mt-3 p-1">
              {formatMessage({
                id: "publicleasing.text.right-disclaimer",
              })}
            </div>
          </div>
        </div>
        <div className="space-modal-portrait"></div>
        <div className="col-third-modal-cta for-main-info">
          <MainInfo
            model={model}
            price={price}
            prePayment={prePayment}
            currMonth={currMonth}
            currKm={currKm}
            currency={currency}
            currResidual={currResidual}
          />
          <div className="right-disclaimer">
            {formatMessage({
              id: "publicleasing.text.right-disclaimer",
            })}
          </div>
        </div>
        <div className="space-modal-portrait"></div>
      </div>
      <div className="col-second-modal-cta for-short-info lanscape-visiavle">
        <h2 className="title-modal-cta-wrapper font-weight-bold ">
          {formatMessage({ id: "publicleasing.title.deal" })}
        </h2>
        <div className="visiable-mobile">
          <MainInfo
            model={model}
            price={price}
            prePayment={prePayment}
            currMonth={currMonth}
            currKm={currKm}
            currency={currency}
            currResidual={currResidual}
          />
        </div>

        <div className="short-info-wrapper">
          <ShortInfo
            price={price}
            leasePrice={leasePrice}
            subTitle={subTitle}
            nomInterest={nomInterest}
            effInterest={effInterest}
            border={true}
            currency={currency}
          />
        </div>
        <div className="mb-3">
          <h4 className="font-weight-bold question-text-wrapper">
            {formatMessage({
              id: "publicleasing.title.next-step",
            })}
          </h4>
        </div>
        <div className="mb-3 flow3-btn">
          {showTeslaConfigurator && (
            <div className="mr-3">
              <Button
                title={formatMessage({
                  id: "publicleasing.button.configure-tesla",
                })}
                type="primary"
                onClick={() => {
                  window.open(getLink(locale, "TESTLA_CONFIGURATOR"), "_blank");
                }}
              />
            </div>
          )}
          <div className="ltq-config-modal">
            <Button
              title={formatMessage({
                id: "publicleasing.button.contact-us",
              })}
              type="secondary"
              onClick={printOffer}
            />
          </div>
          <div className="right-disclaimer-mobile mt-3 p-1">
            {formatMessage({
              id: "publicleasing.text.right-disclaimer",
            })}
          </div>
        </div>
      </div>
      <div className="space-modal-cta"></div>
      <div className="col-third-modal-cta for-main-info lanscape-visiavle">
        <MainInfo
          model={model}
          price={price}
          prePayment={prePayment}
          currMonth={currMonth}
          currKm={currKm}
          currency={currency}
          currResidual={currResidual}
        />
        <div className="right-disclaimer">
          {formatMessage({
            id: "publicleasing.text.right-disclaimer",
          })}
        </div>
      </div>
      <div className="space-modal-cta"></div>
    </div>
  );

  return ModalWithOutImage;
}

ModalCta.propTypes = {};

export default ModalCta;
