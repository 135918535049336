import React, { useState } from "react";
import { CloseButton, Col, Modal, Row } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
import qs from "query-string";
import { useLocale } from "context/locale";
import "./languageSidePanel.css";
import queryString from "query-string";
import { LANGUAGE_OPTIONS_PUBLICLEASING, RESPONSIVE } from "constant";
import { useMediaQuery } from "react-responsive";

function LanguageSidePanel(props) {
  const handleClose = () => {
    document
      .querySelector(".custom-offcanvas")
      .classList.add("custom-fade-out");
    setTimeout(() => {
      props.setShow(false);
    }, "1000");
  };
  const handleShow = () =>
    document.querySelector(".custom-offcanvas").classList.add("custom-fade-in"); //props.setShow(true);

  const { setLocale, locale } = useLocale();
  const location = useLocation();
  const history = useHistory();
  const { search } = useLocation();
  const { country } = queryString.parse(search);

  return (
    <>
      <Modal
        className="custom-offcanvas"
        show={props.show}
        onEntered={handleShow}
        onHide={handleClose}
        dialogClassName="modal-right-offcanvas p-4">
        <Modal.Body className="modal-body-offcanvas d-flex align-items-center justify-content-center">
          <Col>
            <Row>
              <h4>Welche Landesversion und Sprache möchten Sie nutzen?</h4>
            </Row>
            <Row className="pt-5">
              <h5>Schweiz</h5>
            </Row>
            <Row className="pt-2">
              <a
                className={`language-option`}
                href="https://app.leaseteq.com/#/leasingcalculator?country=de_CH">
                Deutsch
              </a>
              <a
                className={`pl-3 language-option`}
                href="https://app.leaseteq.com/#/leasingcalculator?country=fr_CH">
                Français
              </a>
              <a
                className={`pl-3 language-option`}
                href="https://app.leaseteq.com/#/leasingcalculator?country=it_CH">
                Italiano
              </a>
              <a
                className={`pl-3 language-option`}
                href="https://app.leaseteq.com/#/leasingcalculator?country=en_CH">
                English
              </a>
            </Row>
            <Row className="pt-5">
              <h5>Österreich</h5>
            </Row>
            <Row className="pt-2">
              {LANGUAGE_OPTIONS_PUBLICLEASING.map((element, index) => {
                return (
                  <span
                    className={`language-option ${
                      element.value.toLowerCase() === locale &&
                      "custom-selected"
                    } ${index > 0 ? "pl-3" : ""}`}
                    key={index + element.value.toLowerCase()}
                    onClick={() => {
                      setLocale(element.value.toLowerCase());
                      const queryParams = qs.parse(location.search);
                      delete queryParams.country;
                      const newQueries = {
                        ...queryParams,
                        country: `${element.value.toLowerCase()}_AT`,
                      };
                      history.push({ search: qs.stringify(newQueries) });
                    }}>{`${element.label}`}</span>
                );
              })}
            </Row>
          </Col>
        </Modal.Body>

        <div onClick={handleClose} className="close-btn-offcanvas">
          <div className="custom-close"></div>
        </div>
      </Modal>
    </>
  );
}

export default LanguageSidePanel;
