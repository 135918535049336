export const laufzeitData = [
  {
    title: "72 Monate",
    value: 72,
  },
  {
    title: "60 Monate",
    value: 60,
  },
  {
    title: "48 Monate",
    value: 48,
  },
  {
    title: "42 Monate",
    value: 42,
  },
  {
    title: "36 Monate",
    value: 36,
  },
  {
    title: "24 Monate",
    value: 24,
  },
  {
    title: "12 Monate",
    value: 12,
  },
];

export const currKmData = [
  {
    title: "10000 Km",
    value: 10000,
  },
  {
    title: "15000 Km",
    value: 15000,
  },
  {
    title: "20000 Km",
    value: 20000,
  },
  {
    title: "25000 Km",
    value: 25000,
  },
  {
    title: "30000 Km",
    value: 30000,
  },
  {
    title: "35000 Km",
    value: 35000,
  },
  {
    title: "40000 Km",
    value: 40000,
  },
];
