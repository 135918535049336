import React, { Fragment, useRef } from "react";
import { Button, Col, Image, Row } from "react-bootstrap";
import "./HeaderV2.css";
import Logo from "../../assets/logo.png";
import { useMediaQuery } from "react-responsive";
import { RESPONSIVE } from "constant";
import LanguageSwicherDropdown from "components/LanguageSwicherDropdown";
import { useIntl } from "react-intl";
import { getLink } from "screen/LeasingFormPublic/links-configuration";
import { useLocale } from "context/locale";
import { faGlobe } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LanguageSidePanel from "screen/LeasingFormPublic/LanguageSidePanel";
import svgGlobeFile from "./../../assets/icons/globeIcon.svg";
import LanguageSwicher from "components/LanguageSwicher";

function Header(props) {
  const isSmallSize = useMediaQuery({ maxWidth: RESPONSIVE.MAX_WIDTH_SMALL });
  const { locale } = useLocale();

  const { formatMessage } = useIntl();
  return (
    <Fragment>
      {isSmallSize ? (
        <div className="mobil-header">
          <div>
            <Image src={Logo} className="logo" />
          </div>
          <div>
            <Row>
              <Button
                variant="white"
                className="w-50 shadow-none aligh-Region-Icon-Center"
                onClick={() => props.setShow(!props.show)}>
                <img src={svgGlobeFile} alt="React Logo" />
              </Button>
              <h5 className="pt-2 aligh-Region-Text-Center">AT</h5>
            </Row>
          </div>
        </div>
      ) : (
        <div className="sticky-header">
          <div className="sticky-header-first-wrap">
            <div className="logo-wrapper-v2">
              <Image src={Logo} alt="logo-header" className="logo-header" />
            </div>
            <div className="link-wrapper">
              <a href={getLink(locale, "HOME_PAGE")}>
                <span className="font-weight-bold mb-0 menu-link">
                  {formatMessage({ id: "publicleasing.text.homepage" })}
                </span>
              </a>
              <a
                href={getLink(locale, "ABOUT_US")}
                style={{ paddingLeft: "2px" }}>
                <span className="font-weight-bold mb-0 menu-link">
                  {formatMessage({ id: "publicleasing.text.about-us" })}
                </span>
              </a>

              <a href="https://app-at.leaseteq.com/#/leasingcalculator">
                <span className="font-weight-bold mb-0 menu-link active">
                  {formatMessage({
                    id: "publicleasing.text.leasingcalculator",
                  })}
                </span>
              </a>
            </div>
          </div>
          <Row>
            <Button
              variant="white"
              style={{ scale: "1.2", boxShadow: "none" }}
              className="w-50 aligh-Region-Icon-Center"
              onClick={() => props.setShow(!props.show)}>
              <img src={svgGlobeFile} alt="React Logo" />
            </Button>
            <h5 className="pt-2 aligh-Region-Text-Center">AT</h5>
          </Row>
        </div>
      )}
      <LanguageSidePanel show={props.show} setShow={props.setShow} />
    </Fragment>
  );
}

Header.propTypes = {};

export default Header;
