import { FormButton, FormInput, InputNumber } from "components";
import { format } from "currency-formatter";
import _ from "lodash";
import { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { useMediaQuery } from "react-responsive";

import InputRow from "components/InputRow";
import LockIcon from "components/LockIcon";
import { RESPONSIVE } from "constant";
import { useLocale } from "context/locale";
import { Controller, useFormContext } from "react-hook-form";
import { Document, Page, pdfjs } from "react-pdf";
import { colors } from "themes/colors";
import { getTerm } from "utils/api";
import Layout from "../../../components/Layout";
import "./LivingAndExpenses.css";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const ContractModal = ({ children, ...props }) => {
  const [pdfLink, setPDFLink] = useState();
  const { locale } = useLocale();

  // Get pdf url
  // useEffect(async () => {
  //   const { data } = await getTerm(locale);
  //   setPDFLink(data.pdfURL);
  // }, [locale]);
  useEffect(() => {
    async function fetchData() {
      const { data } = await getTerm(locale, false);
        setPDFLink(data.pdfURL);
    }
    fetchData();
  }, [locale]);

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="overflow-auto position-static">
      <Modal.Body>{children}</Modal.Body>
      <Modal.Footer>
        <a href={pdfLink} download="LeaseteqTC">
          <Button variant="secondary">
            <FormattedMessage id="expenses.button.contractDownload" />
          </Button>
        </a>
        <Button variant="secondary" onClick={props.onHide}>
          <FormattedMessage id="expenses.button.contractPopupCancel" />
        </Button>
        <Button disabled={!props.isReadAll} onClick={props.onConfirm}>
          <FormattedMessage id="expenses.button.contractPopupRead" />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default function LivingAndExpensesScreen({
  stepperOptions,
  onNextStep,
  onBackStep,
}) {
  const {
    watch,
    formState: { errors, touchedFields },
    getValues,
    control,
    setValue,
  } = useFormContext();
  const isMobile = useMediaQuery({ maxWidth: RESPONSIVE.MAX_WIDTH_SMALL });
  const isMedium = useMediaQuery({
    minWidth: RESPONSIVE.MIN_WIDTH_MEDIUM,
    maxWidth: RESPONSIVE.MAX_WIDTH_MEDIUM,
  });
  const is4K = useMediaQuery({ minWidth: RESPONSIVE.MIN_WIDTH_4K });
  const [isTouched, setTouched] = useState(false);
  const [isOpenModal, setOpenModal] = useState(false);
  const { locale } = useLocale();
  const [numPages, setNumPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [pdfLink, setPDFLink] = useState();
  const [isLoadSuccess, setLoadSuccess] = useState(false);
  const [zoom, setZoom] = useState(1);

  // Validate and go to next page
  const _onNextPage = async () => {
    setTouched(true);
    const isValid = await stepperOptions.triggerForm(4);
    if (isValid) {
      onNextStep();
    }
  };

  // Validate and go to previous page
  const _onBackStep = async () => {
    await stepperOptions.triggerForm(4);
    onBackStep();
  };

  // Update pdf page
  const changePage = (offset) => {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  };

  // Go to previous pdf page
  const previousPage = () => {
    changePage(-1);
  };

  // Go to next pdf page
  const nextPage = () => {
    changePage(1);
  };

  // Update number of page when pdf load successful
  const onDocumentLoadSuccess = ({ numPages }) => {
    setLoadSuccess(true);
    setNumPages(numPages);
  };

  // Scroll view to top
  useEffect(() => {
    document
      .getElementsByClassName(
        `${isMobile ? "MuiStepLabel-active" : "active"}`
      )[0]
      .scrollIntoView({ behavior: "smooth", inline: "center" });
    window.scrollTo(0, 0);
  }, []);

  // Get pdf url
  useEffect(async () => {
    const { data } = await getTerm(locale);
    setPDFLink(data.pdfURL);
  }, [locale]);

  return (
    <>
      <style>{`
        .ic {
          color: ${colors.gray4}
        }

        .pdf-center {
          display: flex;
          justify-content: center;
        }

        .pdf-resolution {
          max-height: ${
            window.innerHeight *
            (isMobile || isMedium ? 0.5 : is4K ? 0.9 : 0.75)
          }px !important;
        }
    `}</style>
      <Layout
        title={
          <FormattedMessage
            id="expenses.title.pageHeader"
            /* defaultMessage="Wohnen & Ausgaben" */
          />
        }
        stepperOptions={stepperOptions}
        onNextStep={_onNextPage}
        onBackStep={_onBackStep}
        footer={{ icon: <LockIcon /> }}>
        <div style={{ marginTop: 15 }} />

        {isOpenModal && (
          <Document
            loading={""}
            noData={""}
            file={pdfLink}
            onLoadSuccess={onDocumentLoadSuccess}
            onLoadError={() => setOpenModal(false)}>
            <ContractModal
              show={isLoadSuccess}
              onHide={() => {
                setOpenModal(false);
              }}
              onConfirm={() => {
                setValue("livingContract", true);
                setOpenModal(false);
              }}
              animation={true}
              isReadAll={pageNumber == numPages}>
              <Page
                className={`overflow-auto pdf-resolution ${
                  zoom === 1 && "pdf-center"
                }`}
                scale={zoom}
                height={isMobile || isMedium ? window.innerHeight * 0.5 : 1550}
                pageNumber={pageNumber}
              />
              <Row className="pt-3">
                <Col className="d-flex flex-row justify-content-center">
                  {(isMobile || isMedium) && (
                    <div
                      className="d-flex w-100"
                      style={{ justifyContent: "flex-start !important" }}>
                      <Button
                        className="mr-2"
                        disabled={zoom === 1}
                        onClick={() => {
                          setZoom(zoom - 1);
                        }}
                        variant="secondary">{`-`}</Button>
                      <Button
                        onClick={() => {
                          setZoom(zoom + 1);
                        }}
                        variant="secondary">{`+`}</Button>
                    </div>
                  )}
                  {numPages > 1 && (
                    <div className="d-flex ml-2">
                      {/*<a href={pdfLink} download="PDF"><Button
                        variant="secondary"
                  >{`download`}</Button></a>*/}
                      <Button
                        disabled={pageNumber <= 1}
                        onClick={previousPage}
                        variant="secondary">{`<`}</Button>
                      <div className="h3 mx-3">{pageNumber}</div>
                      <Button
                        disabled={pageNumber >= numPages}
                        onClick={nextPage}
                        variant="secondary">{`>`}</Button>
                    </div>
                  )}
                </Col>
              </Row>
            </ContractModal>
          </Document>
        )}

        <Controller
          name="wohnsituation"
          control={control}
          defaultValue={getValues("wohnsituation")}
          render={({ field }) => {
            return (
              <FormButton
                moreSpace
                isRequired
                isShowError={!!errors["wohnsituation"]}
                isValid={field.value}
                label={
                  <FormattedMessage
                    id="expenses.button.housingSituation"
                    /* defaultMessage="Wohnsituation" */
                  />
                }
                buttons={[
                  {
                    icon: "icon-person-group",
                    label: (
                      <FormattedMessage
                        id="expenses.icon.partner"
                        /* defaultMessage="Lebens-partner/in" */
                      />
                    ),
                    value: "Lebenspartner/in",
                  },
                  {
                    icon: "icon-person",
                    label: (
                      <FormattedMessage
                        id="expenses.icon.alone"
                        /* defaultMessage="Alleine wohnend" */
                      />
                    ),
                    value: "Alleine wohnend",
                  },
                  {
                    icon: "icon-people_23",
                    label: (
                      <FormattedMessage
                        id="expenses.icon.residentialCommunity"
                        /* defaultMessage="Wohn-gemeinschaft" */
                      />
                    ),
                    value: "Wohngemeinschaft",
                  },
                  {
                    icon: "icon-people_20",
                    label: (
                      <FormattedMessage
                        id="expenses.icon.parents"
                        /* defaultMessage="Bei Eltern" */
                      />
                    ),
                    value: "Bei Eltern",
                  },
                  {
                    icon: "icon-children",
                    label: (
                      <FormattedMessage
                        id="expenses.icon.singlePartent"
                        /* defaultMessage="Allein-erziehend" */
                      />
                    ),
                    value: "Alleinerziehend",
                  },
                ]}
                onClick={(e, value) => {
                  field.onChange(value);
                }}
                {...field}
              />
            );
          }}
        />

        <Controller
          name="wohnMietkosten"
          control={control}
          defaultValue={watch("wohnMietkosten")}
          render={({ field }) => {
            return (
              <InputRow
                label={
                  <FormattedMessage
                    id="expenses.input.rent"
                    /* defaultMessage="Wohn-und Mietkosten (pro Monat inkl. Nebenkosten)" */
                  />
                }
                isShowError={!!errors["wohnMietkosten"]}
                isValid={
                  touchedFields["wohnMietkosten"] && !errors["wohnMietkosten"]
                }
                isRequired>
                <Row className="d-flex align-items-center">
                  <Col md={5}>
                    <Form.Control
                      inputMode="numeric"
                      className="input-shadow"
                      required
                      {...field}
                      onChange={(event) => {
                        event.preventDefault();
                        let valueInput = event.target.value
                          .split(".")
                          .join("")
                          .trim();
                        // if (valueInput === "" || valueInput === "00" || valueInput === "0") {
                        //   valueInput = 0
                        // }
                        if (valueInput > 0) {
                          setValue("BegruendungWohnen", "");
                        }
                        if (!_.isNaN(Number(valueInput))) {
                          setValue("wohnMietkosten", valueInput);
                        }
                      }}
                      value={
                        field.value
                          ? format(field.value, { thousand: ".", decimal: ",", precision: 0 })
                          : field.value
                      }
                    />
                  </Col>
                  <Col md={4}>
                    <p className="text-secondary align-middle p-0 m-0">
                      <FormattedMessage id="common.text.monthlymoney" />
                    </p>
                  </Col>
                </Row>
              </InputRow>
            );
          }}
        />

        {watch("wohnMietkosten") === "0" && (
          <Controller
            name="BegruendungWohnen"
            control={control}
            defaultValue={getValues("BegruendungWohnen")}
            render={({ field }) => {
              return (
                <InputRow
                  isShowError={!!errors.BegruendungWohnen}
                  label={
                    <FormattedMessage
                      id="expenses.input.justification"
                      /* defaultMessage="Begründung" */
                    />
                  }
                  isRequired
                  isValid={
                    touchedFields["BegruendungWohnen"] &&
                    !errors.BegruendungWohnen
                  }>
                  <Row className="d-flex align-items-center">
                    <Col md={8}>
                      <Form.Control
                        className="input-shadow"
                        required
                        {...field}
                      />
                    </Col>
                    {/* <Col md={12} className="text-danger">
                    {errors.BegruendungWohnen?.message && errors.BegruendungWohnen?.message}
                  </Col> */}
                  </Row>
                </InputRow>
              );
            }}
          />
        )}

        {/* TODO: Need refactor validate */}

        <Controller
          name="kinderHaushalt"
          control={control}
          defaultValue={getValues("kinderHaushalt")}
          render={({ field }) => {
            return (
              <FormButton
                isLeft
                isRequired
                isShowError={!!errors["kinderHaushalt"]}
                isValid={field.value}
                label={
                  <FormattedMessage
                    id="expenses.button.childrenInHousehold"
                    /* defaultMessage="Kinder im gleichen Haushalt" */
                  />
                }
                buttons={[
                  {
                    text: (
                      <FormattedMessage
                        id="expenses.button.yes"
                        /* defaultMessage="Ja" */
                      />
                    ),
                    value: "Ja",
                  },
                  {
                    text: (
                      <FormattedMessage
                        id="expenses.button.no"
                        /* defaultMessage="Nein" */
                      />
                    ),
                    value: "Nein",
                  },
                ]}
                onClick={(e, value) => {
                  field.onChange(value);
                  if (value == "Nein") {
                    setValue("bisSechsJahre", 0);
                    setValue("bisZwoelfJahre", 0);
                    setValue("abZwoelfJahre", 0);
                  }
                }}
                {...field}
              />
            );
          }}
        />

        {watch("kinderHaushalt") === "Ja" && (
          <InputRow
            isShowError={
              isTouched &&
              watch("kinderHaushalt") === "Ja" &&
              !(
                watch("bisSechsJahre") ||
                watch("bisZwoelfJahre") ||
                watch("abZwoelfJahre")
              ) && <FormattedMessage id="validation.text.numberisnot0" />
            }
            label={
              <FormattedMessage
                id="expenses.button.childrenAmount"
                /* defaultMessage="Anzahl Kinder" */
              />
            }
            isRequired
            isValid={
              watch("bisSechsJahre") ||
              watch("bisZwoelfJahre") ||
              watch("abZwoelfJahre")
            }>
            <Controller
              name="bisSechsJahre"
              control={control}
              defaultValue={getValues("bisSechsJahre")}
              render={({ field }) => {
                return (
                  <InputNumber
                    label={
                      <FormattedMessage
                        id="expenses.input.childrenSix"
                        /* defaultMessage="Bis 6 Jahre alt" */
                      />
                    }
                    {...field}
                    setValue={(value) => {
                      setValue("bisSechsJahre", value);
                      setTouched(true);
                    }}
                  />
                );
              }}
            />

            <Controller
              name="bisZwoelfJahre"
              control={control}
              defaultValue={getValues("bisZwoelfJahre")}
              render={({ field }) => {
                return (
                  <InputNumber
                    label={
                      <FormattedMessage
                        id="expenses.input.childrenSeven"
                        /* defaultMessage="7-12 Jahre alt" */
                      />
                    }
                    {...field}
                    setValue={(value) => {
                      setValue("bisZwoelfJahre", value);
                      setTouched(true);
                    }}
                  />
                );
              }}
            />

            <Controller
              name="abZwoelfJahre"
              control={control}
              defaultValue={getValues("abZwoelfJahre")}
              render={({ field }) => {
                return (
                  <InputNumber
                    label={
                      <FormattedMessage
                        id="expenses.input.childrenTwelve"
                        /* defaultMessage="über 12 Jahre alt" */
                      />
                    }
                    {...field}
                    setValue={(value) => {
                      setValue("abZwoelfJahre", value);
                      setTouched(true);
                    }}
                  />
                );
              }}
            />
          </InputRow>
        )}

        <Controller
          name="andereRegelAuslagen"
          control={control}
          defaultValue={watch("andereRegelAuslagen")}
          render={({ field }) => {
            return (
              <InputRow
                tooltip={
                  <FormattedMessage
                    id="expenses.input.otherExpensesRegelAuslagen.tooltip"
                    /* defaultMessage="Monatliches Nettoeinkommen" */
                  />
                }
                label={
                  <FormattedMessage
                    id="expenses.input.otherExpenses"
                    /* defaultMessage="Andere regelmässige Auslagen" */
                  />
                }
                isShowError={!!errors["andereRegelAuslagen"]}
                isValid={
                  touchedFields["andereRegelAuslagen"] &&
                  !errors["andereRegelAuslagen"]
                }
                isRequired>
                <Row className="d-flex align-items-center">
                  <Col md={5}>
                    <Form.Control
                      id="auslagen_textfield"
                      inputMode="numeric"
                      className="input-shadow"
                      required
                      {...field}
                      onChange={(event) => {
                        event.preventDefault();
                        let valueInput = event.target.value
                          .split(".")
                          .join("")
                          .trim();
                        // if (valueInput === "" || valueInput === "00" || valueInput === "0") {
                        //   valueInput = 0
                        // }
                        if (!_.isNaN(Number(valueInput))) {
                          setValue("andereRegelAuslagen", valueInput);
                        }
                      }}
                      value={
                        field.value
                          ? format(field.value, { thousand: ".", decimal: ",", precision: 0 })
                          : field.value
                      }
                    />
                  </Col>
                  <Col md={4}>
                    <p className="text-secondary align-middle p-0 m-0">
                      <FormattedMessage id="common.text.monthlymoney" />
                    </p>
                  </Col>
                </Row>
              </InputRow>
            );
          }}
        />

        <Controller
          name="VertragersatzChecked"
          control={control}
          defaultValue={getValues("VertragersatzChecked")}
          render={({ field }) => {
            return (
              <FormButton
                isLeft
                isShowError={
                  !!errors["Vertragersatz"] || !!errors["VertragersatzChecked"]
                }
                isRequired
                isValid={
                  watch("VertragersatzChecked") === "Ja"
                    ? watch("Vertragersatz") !== 0 &&
                      touchedFields["Vertragersatz"] &&
                      watch("Vertragersatz") !== ""
                      ? true
                      : false
                    : watch("VertragersatzChecked") === "Nein"
                    ? true
                    : false
                }
                label={
                  <FormattedMessage
                    id="expenses.button.replaceExistingContract"
                    /* defaultMessage="Ersetzt diese Anfrage einen bestehenden Vertrag" */
                  />
                }
                buttons={[
                  {
                    text: (
                      <FormattedMessage
                        id="expenses.button.yes"
                        /* defaultMessage="Ja" */
                      />
                    ),
                    value: "Ja",
                  },
                  {
                    text: (
                      <FormattedMessage
                        id="expenses.button.no"
                        /* defaultMessage="Nein" */
                      />
                    ),
                    value: "Nein",
                  },
                ]}
                onClick={(e, value) => {
                  e.preventDefault();
                  field.onChange(value);
                  if (value === "Nein") {
                    setValue("Vertragersatz", "");
                    // clearErrors("Vertragersatz")
                  }
                }}
                {...field}>
                {watch("VertragersatzChecked") === "Ja" && (
                  <Row
                    className={`d-flex align-items-center ml-2 ${
                      isMobile ? "w-55" : "w-80"
                    }`}>
                    <div className="sub-field">
                      <Controller
                        name="Vertragersatz"
                        control={control}
                        defaultValue={watch("Vertragersatz")}
                        render={({ field }) => {
                          return (
                            <Form.Control
                              inputMode="numeric"
                              className="input-shadow"
                              required
                              {...field}
                              onChange={(event) => {
                                event.preventDefault();
                                let valueInput = event.target.value
                                  .split(".")
                                  .join("")
                                  .trim();
                                if (!_.isNaN(Number(valueInput))) {
                                  setValue("Vertragersatz", valueInput);
                                }
                              }}
                              value={
                                field.value
                                  ? format(field.value, {
                                      thousand: ".",
                                      decimal: ",",
                                      precision: 0,
                                    })
                                  : field.value
                              }
                            />
                          );
                        }}
                      />
                    </div>
                    <Col md={5} className={`${isMobile ? "p-0" : ""}`}>
                      <p className="text-secondary align-middle p-0 m-0">
                        <FormattedMessage id="common.text.monthlymoney" />
                      </p>
                    </Col>
                  </Row>
                )}
              </FormButton>
            );
          }}
        />

        <Row>
          <Col md={4} sm={0}></Col>
          {/* <Col md={8} sm={12} className="text-danger" >{errors.Vertragersatz && watch("VertragersatzChecked") === "Ja" && errors.Vertragersatz.message}</Col> */}
        </Row>

        <Controller
          name="Bemerkungen"
          control={control}
          defaultValue={getValues("Bemerkungen")}
          render={({ field }) => {
            return (
              <FormInput
                isArea
                isLabelEnd
                isShowError={!!errors["Bemerkungen"]}
                isValid={
                  touchedFields["Bemerkungen"] && !errors.Bemerkungen?.message
                }
                label={
                  <FormattedMessage
                    id="expenses.input.Comments"
                    /* defaultMessage="Bemerkungen / Fragen zur Finanzierungsanfrage" */
                  />
                }
                {...field}
                // errorMessage={errors.Bemerkungen?.message && errors.Bemerkungen?.message}
              />
            );
          }}
        />

        <Row style={{ marginTop: 30 }}>
          <Col>
            <Controller
              control={control}
              name="livingContract"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <Form.Group controlId="livingContractCheckbox">
                  <b>
                    <Form.Check
                      onBlur={onBlur}
                      onChange={(e) => {
                        if (!getValues("livingContract")) {
                          setOpenModal(true);
                        }
                      }}
                      checked={value}
                      type="checkbox"
                      label={
                        <>
                          <FormattedMessage
                            id="expenses.button.contract"
                            /* defaultMessage="Ich versichere alle Angaben wahrheitsgemäß gemacht zu haben" */
                          />
                          <sup className="required"> *</sup>
                        </>
                      }
                      custom
                      ref={ref}
                    />
                  </b>
                </Form.Group>
              )}
            />
          </Col>
        </Row>

        <Row>
          <Col>
            <Controller
              control={control}
              name="livingAgree"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <Form.Group controlId="livingAgreeCheckbox">
                  <b>
                    <Form.Check
                      data-cy="livingAgreeCheckbox"
                      onBlur={onBlur}
                      onChange={onChange}
                      checked={value}
                      type="checkbox"
                      label={
                        <>
                          <FormattedMessage
                            id="expenses.button.agreement"
                            /* defaultMessage="Sie sind einverstanden, dass wir Bonitätsinformationen über Sie einholen" */
                          />
                          <sup className="required"> *</sup>
                        </>
                      }
                      custom
                      ref={ref}
                    />
                  </b>
                </Form.Group>
              )}
            />
          </Col>
        </Row>

        <Row>
          <Col>
            <Controller
              control={control}
              name="livingConfirm"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <Form.Group controlId="livingConfirmCheckbox">
                  <b>
                    <Form.Check
                      data-cy="livingConfirmCheckbox"
                      onBlur={onBlur}
                      onChange={onChange}
                      checked={value}
                      type="checkbox"
                      label={
                        <>
                          <FormattedMessage
                            id="expenses.button.confirmation"
                            /* defaultMessage="Ich versichere alle Angaben wahrheitsgemäß gemacht zu haben" */
                          />
                          <sup className="required"> *</sup>
                        </>
                      }
                      custom
                      ref={ref}
                    />
                  </b>
                </Form.Group>
              )}
            />
          </Col>
        </Row>

        {/* <Row>
          <Col md={12} className="text-danger">{errors.livingConfirm && errors.livingConfirm.message}</Col>
          <Col md={12} className="text-danger">{errors.livingAgree && errors.livingAgree.message}</Col>
        </Row> */}
      </Layout>
    </>
  );
}
