import ButtonComponent from "components/button/buttonComponent";
import Header from "components/Header";
import infoIcon from "components/icon/info.svg";
import InputComponent from "components/input/inputComponent";
import SliderComponent from "components/slider/sliderComponent";
import { RESPONSIVE } from "constant";
import { format } from "currency-formatter";
import React, { useEffect, useState } from "react";
import { Col, Form, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import { useMediaQuery } from "react-responsive";
import { useParams } from "react-router-dom";
import { pmt } from "utils";
import { getInitialValues, getInitialValuesWithOptions } from "utils/api";
import BoxContent from "./BoxContent";
import "./calculatorScreen.css";

export default function CalculatorBusiness({ stepperOptions, onNextStep }) {
  // Get Params from API and userFormContext to access them
  const { marke } = useParams();
  const { getValues, setValue } = useFormContext();

  // Create month boundaries
  const [minMonth, setMinMonth] = useState(12);
  const [currMonth, setCurrMonth] = useState(60);
  const [maxMonth, setMaxMonth] = useState(60);

  // Create kilometer boundaries
  const [minKm, setMinKm] = useState(10000);
  const [currKm, setCurrKm] = useState(10000);
  const [maxKm, setMaxKm] = useState(60000);

  // Create residual boundaries
  // const [minResidual, setMinResidual] = useState(0);
  const [currResidual, setCurrResidual] = useState(0);
  const [maxResidual, setMaxResidual] = useState(0);
  const [minResidual, setMinResidual] = useState(0);

  // Create reservationfee boundaries (MaxDownPayment)
  const [minReservationFee, setMinReservationFee] = useState(0);
  const [currReservationFee, setCurrReservationFee] = useState(0);
  const [maxReservationFee, setMaxReservationFee] = useState(0);

  // monthly leasing rate
  const [monthlyLeasingRate, setMonthlyLeasingRate] = useState(0);

  // nomInterest
  const [nomInterest, setNomInterest] = useState(0);

  // terms
  const [terms, setTerms] = useState([]);
  const [excludedTerms, setExcludedTerms] = useState([]);

  const [excludedMileage, setExcludedMileage] = useState([]);
  const [includedMileage, setIncludedMileage] = useState([]);

  // termDependency
  // const [termDependency, setTermDependency] = useState({});

  // carPrice
  const carPrice = getValues("carPrice");

  // currResidualValueTable (Type Object)
  const [currResidualValueTable, setCurrResidualValueTable] = useState({});

  // residualValueTables
  const [residualValueTables, setResidualValueTables] = useState([]);

  // CSS Settings
  const isSmallSize = useMediaQuery({ maxWidth: RESPONSIVE.MAX_WIDTH_SMALL });
  const isMediumSize = useMediaQuery({ minWidth: RESPONSIVE.MIN_WIDTH_MEDIUM, maxWidth: RESPONSIVE.MAX_WIDTH_MEDIUM });
  const isLargeSize = useMediaQuery({ minWidth: RESPONSIVE.MIN_WIDTH_LARGE });

  useEffect(() => {
    const getModelDataFromTeslaApi = () => {
      const model = getValues("model");
      const customer = "Business";
      const isNew = getValues("vehicleTypeNew");
      const isInventory = getValues("isInventory");
      const options = getValues("carOptions");

      getInitialValuesWithOptions(model, customer, isNew, options, isInventory).then(({ data }) => {
        setModelDataFromTeslaApi(data);
        setValue("examplaryPicture", data.examplaryPicture);
        setValue("brand", data.brand)
      })
    }
    getModelDataFromTeslaApi();
  }, []);

  const setModelDataFromTeslaApi = (data) => {
    if (data === 'undefined') {
    } else {
      let excludedTerms = Object.keys(data.termToggles).filter(key => !data.termToggles[key]);
      let includedTerms = Object.keys(data.termToggles).filter(key => data.termToggles[key]);
      excludedTerms = excludedTerms.length > 0 && includedTerms.length > 0 ? excludedTerms : [];
      includedTerms = includedTerms.length > 0 ? includedTerms : ["12", "18", "24", "30", "36", "42", "48", "54", "60", "66", "72"];
      setTerms(includedTerms);
      setExcludedTerms(excludedTerms);
      setMinMonth(Number(includedTerms[0]));
      setMaxMonth(Number(includedTerms.at(-1)));
      if (currMonth > Number(includedTerms.at(-1))) {
        setCurrMonth(Number(includedTerms.at(-1)));
      }
      let residualValuesOEM = data.residualValuesOEM;
      for (let i = 0; i < residualValuesOEM.length; i++) {
        for (let j = 0; j < excludedTerms.length; j++) {
          delete residualValuesOEM[i][excludedTerms[j]+"months"];
        }
      }

      let excludedMileage = [];
      let includedMileages =  residualValuesOEM.map((item: { Mileage: number }) => item.Mileage);
      for (let i=includedMileages[0]; i <= includedMileages[includedMileages.length-1]; i=i+5000) {
        if (! includedMileages.includes(i))
            excludedMileage.push(i);
      }
      setMinKm(includedMileages[0]);
      setMaxKm(includedMileages[includedMileages.length-1]);
      setExcludedMileage(excludedMileage.map(String));
      setIncludedMileage(includedMileages.map(String));

      setDownPaymentsValues(data.reservationFee, data.prePaymentTo);
      setResidualValueTables(residualValuesOEM);
      let object = findAppropriateResidualObject(
        residualValuesOEM,
        currKm
      );
      setCurrResidualValueTable(object);
      setNomInterest(data.nomInterest);
      setMinResidual(data.residualvalueFrom);
      setResidualValues(object);
    }
  }

  useEffect(() => {
    setMonthlyLeasingRate(
      Math.abs(
        pmt(
          nomInterest / 100 / 12,
          currMonth,
          carPrice - currReservationFee,
          currResidual * -1,
          1
        )
      )
    )
    setValue("anzahlung", currReservationFee);
    setValue("laufzeit", currMonth);
    setValue("leasingrate", monthlyLeasingRate);
    setValue("restwert", currResidual);
    setValue("nomInterest", nomInterest);
    setValue("effInterest",
      ((Math.pow(1 + nomInterest / 1200, 12) - 1) * 100).toFixed(
        4
      ));
    setValue("laufleistung", currKm);
  }, [nomInterest, currMonth, currKm, carPrice, currReservationFee, currResidual, monthlyLeasingRate]);

  //return an ARRAY of min,max,curr values for the slider and the inputfield
  const setDownPaymentsValues = (reservationFee, prePaymentTo) => {
    setMinReservationFee(reservationFee);
    setMaxReservationFee(carPrice * prePaymentTo);
    setCurrReservationFee(reservationFee);
  }

  const setMonthsValues = (currResidualValueTable) => {
    let minMaxMonths = getMinMaxMonths(currResidualValueTable);
    setMinMonth(minMaxMonths[0]);
    // check if maxMonth changed to new max boundary & set currMonth to new max boundary
    if (currMonth > minMaxMonths[1]) {
      setCurrMonth(minMaxMonths[1]);
    }
    setMaxMonth(minMaxMonths[1]);
  }

  const setResidualValues = (residualValueTable) => {
    let minMaxResidualValues = getMinMaxResidualValues(residualValueTable);
    let currResidual = carPrice * residualValueTable[currMonth + `months`];
    if (isNaN(currResidual)) {
      setMaxResidual(minMaxResidualValues[0] * carPrice);
      setCurrResidual(minMaxResidualValues[0] * carPrice);
    } else if (!isNaN(currResidual)) {
      setMaxResidual(currResidual)
      setCurrResidual(currResidual);
    }
  }

  const findAppropriateResidualObject = (residualValuesArray, mileAge) => {
    return residualValuesArray.find(residualValueObject => residualValueObject.Mileage === mileAge);
  }

  function getMinMaxMonths(residualObject) {
    let monthsArray = [];
    for (const [key, value] of Object.entries(residualObject)) {
      if (value > 0 && value < 10000) {
        let [monthNumber] = key.match(/\d+/g);
        monthsArray.push(Number(monthNumber));
      }
    }
    return [monthsArray[0], monthsArray[monthsArray.length - 1]];
  }

  function getMinMaxResidualValues(residualObject) {
    let valueArray = [];
    for (const [key, value] of Object.entries(residualObject)) {
      if (value > 0 && value < 10000) {
        valueArray.push(value);
      }
    }
    return [valueArray[valueArray.length - 1], valueArray[0]];
  }

  function findAppropriateResidualObjectByMileage(mileAge) {
    return residualValueTables.find(residualValueTables => residualValueTables.Mileage === mileAge);
  }

  // const updateResidualValueTable = () => {
  //   let residualObjectByMileage = findAppropriateResidualObjectByMileage(currKm);
  //   setCurrResidualValueTable(residualObjectByMileage);
  //   if (residualObjectByMileage !== undefined) {
  //     setMonthsValues(residualObjectByMileage);
  //     setResidualValues(residualObjectByMileage);
  //   }
  // }

  useEffect(() => {
    let residualObjectByMileage = findAppropriateResidualObjectByMileage(currKm);
    if (residualObjectByMileage !== undefined) {
      setResidualValues(residualObjectByMileage);
    }
  }, [currMonth]);

  useEffect(() => {
    let residualObjectByMileage = findAppropriateResidualObjectByMileage(currKm);
    if (residualObjectByMileage !== undefined) {
      setResidualValues(residualObjectByMileage);
      setMonthsValues(residualObjectByMileage);
    }
  }, [currKm]);

  return (
    <div className="layout-root">
      <Header stepperOptions={stepperOptions} />
      <div className={`container calculator-container p-0`}>
        <BoxContent marke={marke} />
        <div className="layout-content calculatorScreen">
          <Form.Group as={Row} className={`${isSmallSize ? "mb-0" : ""}`}>
            <Form.Label
              column
              lg="5"
              className={`${isSmallSize ? "py-0" : ""}`}
            >
              <Row className="justify-content-between">
                <Form.Label
                  column
                  className={
                    "d-flex align-items-center calc-label " +
                    (isLargeSize ? "justify-content-between" : "py-0")
                  }
                  style={{ fontSize: "14pt" }}
                >
                  <div>
                    <span className="font-weight-bold ">
                      <FormattedMessage
                        id="calculator.input.leasingrate"
                      />
                    </span>{" "}
                    (
                    <FormattedMessage
                      id="calculator.helpertext.restwert"
                    />
                    )
                  </div>
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id="leasingrate-tooltip-top">
                        <FormattedMessage
                          id="calculator.input.leasingrate.tooltip"
                        /* defaultMessage="Mit der Überweisung der ersten Leasingrate starten Sie den Vertrag und reduzieren die monatliche Leasingrate." */
                        />
                      </Tooltip>
                    }
                  >
                    <div className="infoIcon d-flex justify-content-center align-items-center">
                      <img src={infoIcon} alt={infoIcon} />
                    </div>
                  </OverlayTrigger>
                </Form.Label>
              </Row>
            </Form.Label>
            <Col lg="7">
              <SliderComponent
                minValue={minReservationFee}
                maxValue={maxReservationFee}
                defaultValue={currReservationFee}
                customFormat={{
                  thousand: ".",
                  decimal: ",",
                  precision: 0,
                }}
                onChange={setCurrReservationFee}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className={`${isSmallSize ? "mb-0" : ""}`}>
            <Form.Label
              column
              lg="5"
              className={`${isSmallSize ? "py-0" : ""}`}
            >
              <Row className="justify-content-between">
                <Form.Label
                  column
                  className={
                    "d-flex align-items-center calc-label " +
                    (isLargeSize ? "justify-content-between" : "py-0")
                  }
                  style={{ fontSize: "14pt" }}
                >
                  <div>
                    <span className="font-weight-bold ">
                      <FormattedMessage
                        id="calculator.input.laufzeit" /* defaultMessage="Laufzeit in Monaten" */
                      />
                    </span>{" "}
                    <FormattedMessage id="common.text.inmonth" />
                  </div>
                </Form.Label>
              </Row>
            </Form.Label>
            <Col lg="7" className="d-flex align-items-center">
              <SliderComponent
                minValue={minMonth}
                maxValue={maxMonth}
                defaultValue={currMonth}
                step={6}
                onChange={setCurrMonth}
                terms={terms}
                excludedTerms={excludedTerms}
                allMarks={true}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className={`${isSmallSize ? "mb-0" : ""}`}>
            <Form.Label
              column
              lg="5"
              className={`${isSmallSize ? "py-0" : ""}`}>
              <Row className="justify-content-between">
                <Form.Label
                  column
                  className={
                    "d-flex justify-content-between align-items-center calc-label " +
                    (isLargeSize ? "" : "py-0")
                  }
                  style={{ fontSize: "14pt" }}
                >
                  <div>
                    <span className="font-weight-bold ">
                      <FormattedMessage
                        id="calculator.input.KM" /* defaultMessage="KM-Fahrleistung pro Jahr" */
                      />
                    </span>{" "}
                    <FormattedMessage id="common.text.inkm" />
                  </div>
                </Form.Label>
              </Row>
            </Form.Label>
            <Col lg="7" className="d-flex align-items-center">
              <SliderComponent
                minValue={minKm}
                maxValue={maxKm}
                defaultValue={currKm}
                customFormat={{
                  thousand: ".",
                  decimal: ",",
                  precision: 0,
                }}
                step={5000}
                terms={includedMileage}
                excludedTerms={excludedMileage}
                onChange={setCurrKm}
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className={`${isSmallSize ? "mb-0" : ""}`}>
            <Form.Label
              column
              lg="5"
              className={`${isSmallSize ? "py-0" : ""}`}
            >
              <Row className="justify-content-between">
                <Form.Label
                  column
                  className={
                    "d-flex justify-content-between align-items-center calc-label" +
                    (isLargeSize ? "" : "py-0")
                  }
                  style={{ fontSize: "14pt" }}
                >
                  <div>
                    <span className="font-weight-bold ">
                      <FormattedMessage
                        id="calculator.input.restwert" /* defaultMessage="Restwert" */
                      />
                    </span>{" "}
                    (
                    <FormattedMessage
                      id="calculator.helpertext.restwert" /* defaultMessage="in CHF inkl. MwSt" */
                    />
                    )
                  </div>
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id="restwert-tooltip-top">
                        <FormattedMessage
                          id="calculator.input.restwert.tooltip" /* defaultMessage="Restwert" */
                        />
                      </Tooltip>
                    }
                  >
                    <div className="infoIcon d-flex justify-content-center align-items-center">
                      <img src={infoIcon} alt={infoIcon} />
                    </div>
                  </OverlayTrigger>
                </Form.Label>
              </Row>
            </Form.Label>
            <Col lg="7">
              <SliderComponent
                minValue={carPrice * minResidual}
                maxValue={maxResidual}
                customFormat={{
                  thousand: ".",
                  decimal: ",",
                  precision: 0,
                }}
                step={0.01}
                defaultValue={currResidual}
                onChange={setCurrResidual}
              />
            </Col>
          </Form.Group>

          {!isSmallSize ? (
            <Row>
              <Col md={5} lg={5}>
                {/* <ButtonComponent
                  className="layout-button custom-calculator-button mb-3"
                  onClick={onNextStep}
                  label={
                    <FormattedMessage
                      id="calculator.input.nextButton" 
                    />
                  }
                /> */}
              </Col>
              <Col md={7} lg={7}>
                <Col>
                  <Form.Group as={Row} className="justify-content-between">
                    <Form.Label
                      className="d-flex align-items-center calc-label interest-label pl-0"
                      column
                      sm="5"
                      lg="7"
                    >
                      <div>
                        <FormattedMessage
                          id="calculator.input.monatliche" /* defaultMessage="Monatliche Leasingrate" */
                        />{" "}
                        <br />
                        <span>
                          <FormattedMessage id="common.text.inchf" />
                        </span>
                      </div>
                    </Form.Label>
                    {isLargeSize && <Col sm="1"></Col>}
                    <Col md="7" lg="3">
                      <Row>
                        <Col md={isMediumSize ? 9 : 12} className={"p-0"}>
                          <InputComponent
                            size={"lg"}
                            className="bg-green "
                            disabled={true}
                            value={format(monthlyLeasingRate, {
                              thousand: ".",
                              decimal: ",",
                              precision: 2,
                            })}
                          />
                        </Col>
                        {isMediumSize && (
                          <Col
                            md={3}
                            className="d-flex align-items-center justify-content-end p-0"
                          >
                            <div className="calc-label">
                              <FormattedMessage
                                id="common.text.money"
                                /* defaultMessage="CHF" */ values={{
                                  value: "",
                                }}
                              />
                            </div>
                          </Col>
                        )}
                      </Row>
                    </Col>
                    {isLargeSize && <Col sm="1" />}
                  </Form.Group>
                </Col>
                <Col className="p-0">
                  <div className="calc-label interest-label">
                    <FormattedMessage
                      id="calculator.helpertext.interest" /* defaultMessage="Zinssatz" */
                    />{" "}
                    {format(nomInterest, { decimal: ",", precision: 4 })}%{" "}
                    <FormattedMessage id="common.text.pa" /> (
                    <FormattedMessage
                      id="calculator.helpertext.effInterest" /* defaultMessage="eff. Zins" */
                    />{" "}
                    {format(
                      (Math.pow(1 + nomInterest / 1200, 12) -
                        1) *
                      100,
                      {
                        decimal: ",",
                        precision: 4,
                      }
                    )}
                    % <FormattedMessage id="common.text.pa" />)
                  </div>
                </Col>
              </Col>
            </Row>
          ) : null}

          {isSmallSize ? (
            <>
              <Row>
                <Col sm={12}>
                  <Form.Group as={Row} className="justify-content-between">
                    <Col xs={10} sm={10} className={"pl-0"}>
                      <Form.Label
                        className="d-flex align-items-center calc-label interest-label py-0"
                        column
                      >
                        <FormattedMessage
                          id="calculator.input.monatliche" /* defaultMessage="Monatliche Leasingrate" */
                        />{" "}
                      </Form.Label>
                    </Col>
                    <Col
                      xs={2}
                      sm={2}
                      className="d-flex align-items-center justify-content-end"
                    >
                      <div className="calc-label ml-1">
                        <FormattedMessage
                          id="common.text.money"
                          /* defaultMessage="CHF" */ values={{ value: "" }}
                        />
                      </div>
                    </Col>
                  </Form.Group>
                </Col>

                <Col xs={12} sm={12}>
                  <InputComponent
                    size={"lg"}
                    className="bg-green "
                    disabled={true}
                    value={format(monthlyLeasingRate, {
                      thousand: ".",
                      decimal: ",",
                      precision: 2,
                    })}
                  />
                </Col>

                <Col sm={12} className="my-2">
                  <div className="calc-label interest-label">
                    <FormattedMessage
                      id="calculator.helpertext.interest" /* defaultMessage="Zinssatz" */
                    />{" "}
                    {format(nomInterest, { decimal: ",", precision: 4 })}%{" "}
                    <FormattedMessage id="common.text.pa" /> (
                    <FormattedMessage
                      id="calculator.helpertext.effInterest" /* defaultMessage="eff. Zins" */
                    />{" "}
                    {format(
                      (Math.pow(1 + nomInterest / 1200, 12) -
                        1) *
                      100,
                      {
                        decimal: ",",
                        precision: 4,
                      }
                    )}
                    % <FormattedMessage id="common.text.pa" />)
                  </div>
                </Col>
              </Row>
            </>
          ) : null}
        </div>
        {isLargeSize ? (
          <Row style={{ backgroundColor: "#fff", color: "#1C1F26" }}>
            <Col md={9}></Col>
            <Col md={3} className="d-flex">
              <ButtonComponent
                style={{ fontSize: "x-large" }}
                className="layout-button custom-color-input my-4"
                onClick={onNextStep}
                label={
                  <FormattedMessage
                    id="calculator.input.nextButton" /* defaultMessage="Jetzt abschliessen" */
                  />
                }
              />
            </Col>
          </Row>
        ) : (
          <Col sm={12} className="p-4" style={{ backgroundColor: "#fff" }}>
            <ButtonComponent
              style={{ fontSize: "x-large" }}
              className="layout-button custom-color-input w-100"
              onClick={onNextStep}
              label={
                <FormattedMessage
                  id="calculator.input.nextButton" /* defaultMessage="Jetzt abschliessen" */
                />
              }
            />
          </Col>
        )}
      </div>
    </div>
  );
}
