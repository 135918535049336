const LINKS = {
  ABOUT_US: {
    de: "https://leaseteq.com/at/uber-uns/",
    en: "https://leaseteq.com/at/en/about-us/",
  },
  IMPRINT: {
    de: "https://leaseteq.com/at/imprint/",
    en: "https://leaseteq.com/at/en/imprint/",
  },
  PRIVACY_POLICY: {
    de: "https://leaseteq.com/at/datenschutz/",
    en: "https://leaseteq.com/at/en/data-privacy/",
  },
  HOME_PAGE: {
    de: "https://leaseteq.com/at/",
    en: "https://leaseteq.com/at/en/",
    it: "https://leaseteq.com/at/it/",
    fr: "https://leaseteq.com/at/fr/",
  },
  TESTLA_CONFIGURATOR: {
    de: "https://www.tesla.com/de_at",
    en: "https://www.tesla.com/de_at",
  },
};

export const getLink = (locale, link) => {
  return LINKS[link][locale];
};
