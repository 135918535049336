import { pmt } from "utils";
import _ from "lodash";
import { format } from "currency-formatter";

export const calcFinalValue = (
  nomInterest,
  currMonth,
  prePayment,
  price,
  currResidual
) => {
  return Math.abs(
    pmt(
      nomInterest / 100 / 12,
      currMonth,
      price - prePayment,
      currResidual * -1,
      1
    )
  );
};

export const calcEffInterest = (nomInterest) => {
  return ((Math.pow(1 + nomInterest / 1200, 12) - 1) * 100).toFixed(2);
};

/**
 *
 * @param {Array} residualValuesOEM
 * @param {number} mileage
 * @param {number} month
 * @returns -1 if no residual value is found, otherwise the residual value
 */
export const getOemValueByMileageAndMonth = (
  residualValuesOEM,
  mileage,
  month
) => {
  const foundOemByMileage = residualValuesOEM.filter(
    (oem) => oem.Mileage === mileage
  );
  if (foundOemByMileage.length === 0) return -1;
  const oemKeys = Object.keys(foundOemByMileage[0]).filter((key) =>
    key.includes(month)
  );
  if (oemKeys.length === 0) return -1;

  return foundOemByMileage[0][oemKeys[0]];
};

/**
 *
 * @param {Array} residualValuesOEM
 * @param {number} mileage
 * @returns -1 if no residual value is found, otherwise the residual value
 */
export const getOemByMileage = (residualValuesOEM, mileage) => {
  const foundOemByMileage = residualValuesOEM.filter(
    (oem) => oem.Mileage === mileage
  );
  if (foundOemByMileage.length === 0) return null;
  return foundOemByMileage[0];
};

export const isCurrResidualEqualsOrGreaterThanMaxResidual = (
  currResidual,
  maxResidual
) => {
  return (
    _.isEqual(Math.round(currResidual), Math.round(maxResidual)) ||
    Math.round(currResidual) > Math.round(maxResidual)
  );
};

export const getMaxMonthOnMonthChange = (newValue) => {
  switch (newValue) {
    case 60000:
      return 36;
    case 50000:
      return 48;
    default:
      return 60;
  }
};

export const leasingCalculatorDefaultState = {
  catalogPrice: 45990,
  modelMarke: "",
  modelType: "",
  customerType: "Private",
  maxMonth: 60,
  currMonth: 48,
  currKm: 20000,
  nomInterest: 0,
  effInterest: 0,
  currResidual: 0,
  prePayment: 0,
  prePaymentFrom: 0,
  prePaymentTo: 0,
  reservationFee: 0,
  residualvalueFrom: 0,
  residualValuesOEM: [],
  finalValue: 0,
  maxResidual: 0,
  modelImageSrc: "",
};

export const getGridSystemForBody = (width) => {
  if (width > 1366) {
    return `calc(1366px/60 * 5) calc(1366px/60 * 23) calc(1366px/60 * 4) calc(1366px/60 * 23) calc(1366px/60 * 5)`;
  }
  if (width >= 768 && width <= 991) {
    return `calc(100vw/60 * 5) calc(100vw/60 * 24) calc(100vw/60 * 3) calc(100vw/60 * 25) calc(100vw/60 * 3)`;
  }

  if (width >= 1024 && width < 1366) {
    return `calc(100vw/60 * 5) calc(100vw/60 * 23) calc(100vw/60 * 3) calc(100vw/60 * 25) calc(100vw/60 * 3)`;
  } else {
    return `calc(100vw/60 * 5) calc(100vw/60 * 23) calc(100vw/60 * 4) calc(100vw/60 * 23) calc(100vw/60 * 5)`;
  }
};

export const getGridSystemForFooter = (width) => {
  if (width > 1366) {
    return `calc(1366px/60 * 7) calc(1366px/60 * 9) calc(1366px/60 * 1) calc(1366px/60 * 17) calc(1366px/60 * 25)`;
  }
  if (width >= 768 && width <= 991) {
    return `calc(100vw/60 * 5) calc(100vw/60 * 13) calc(100vw/60 * 4) calc(100vw/60 * 19) calc(100vw/60 * 19)`;
  } else {
    return `calc(100vw/60 * 5) calc(100vw/60 * 10) calc(1366px/60 * 2) calc(100vw/60 * 19) calc(100vw/60 * 24)`;
  }
};

export const getGridSystemForModalCta = (width) => {
  if (width > 1366) {
    return `calc(1366px/60 * 5) calc(1366px/60 * 2) calc(1366px/60 * 2) auto calc(1366px/60 * 3) auto auto`;
  } else if (width >= 992 && width <= 1192) {
    return `calc(100vw/60 * 5) calc(100vw/60 * 2) calc(100vw/60 * 2) calc(100vw/60 * 21) calc(100vw/60 * 3) calc(100vw/60 * 21) calc(100vw/60 * 5)`;
  } else {
    return `calc(100vw/60 * 5) calc(100vw/60 * 2) calc(100vw/60 * 2) calc(100vw/60 * 19) calc(100vw/60 * 2) calc(100vw/60 * 20) calc(100vw/60 * 10)`;
  }
};

export const getGridSystemForStickyCta = (width) => {
  if (width > 1366) {
    return `calc(1366px/60 * 5) calc(1366px/60 * 2) calc(1366px/60 * 2) calc(1366px/60 * 15) calc(1366px/60 * 2) calc(1366px/60 * 2) calc(1366px/60 * 27)`;
  }
  if (width >= 768 && width <= 1192) {
    return `calc(100vw/60 * 5) calc(100vw/60 * 3) calc(100vw/60 * 2) auto`;
  } else {
    return `calc(100vw/60 * 5) calc(100vw/60 * 2) calc(100vw/60 * 2) calc(100vw/60 * 15) calc(100vw/60 * 2) calc(100vw/60 * 2) calc(100vw/60 * 32)`;
  }
};

export const generateMailBody = (
  formatMessage,
  modelType,
  price,
  prePayment,
  currMonth,
  currKm,
  currResidual,
  finalValue
) => {
  return `
    ${formatMessage({ id: "publicleasing.title.deal" })}\n
    ${formatMessage({ id: "publicleasing.text.model" })}: ${modelType}\n
    ${formatMessage({ id: "publicleasing.text.cashprice" })}: EUR ${format(
    price,
    {
      thousand: "‘",
      precision: 2,
    }
  )}\n
    ${formatMessage({
      id: "publicleasing.input.prepayment",
    })}: EUR ${format(prePayment, {
    thousand: "‘",
    precision: 2,
  })} \n
    ${formatMessage({
      id: "publicleasing.title.term",
    })}: ${currMonth} ${formatMessage({
    id: "publicleasing.button.month",
  })}\n
    ${formatMessage({
      id: "publicleasing.text.annual-mileage",
    })}: ${format(currKm, {
    thousand: "‘",
    precision: 0,
  })} Km\n
    ${formatMessage({
      id: "publicleasing.input.residual",
    })}: EUR ${format(currResidual, {
    thousand: "‘",
    precision: 2,
  })} \n
    ${formatMessage({
      id: "publicleasing.text.cta-leasingrate",
    })}: EUR ${format(finalValue, {
    thousand: "‘",
    precision: 2,
  })} \n

    `;
};
