import React, { useEffect, useState } from "react";
import { getCarModel } from "utils";
import { Col } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import { useMediaQuery } from "react-responsive";
import { RESPONSIVE } from "constant";


const ImageComponent = ({ isLargeSize }) => {
  const { getValues } = useFormContext();
  const defaultImage = getValues("examplaryPicture");
  const [image, setImage] = useState(defaultImage);
  const [isTeslaAsset, setIsTeslaAsset] = useState(false);
  const [showImage, setShowImage] = useState(false);

  useEffect(() => {
    if (getValues("carOptions") !== "undefined" && getValues("brand") === "Tesla") {
      setIsTeslaAsset(true);
      setImage(
        `https://static-assets.tesla.com/configurator/compositor?&options=${getValues(
        "carOptions"
      )}&view=STUD_3QTR&model=${getCarModel(
        getValues("model")
      )}&size=1441&bkba_opt=0`
      );
      setShowImage(true);
    } else {
      setImage(defaultImage);
      if (defaultImage) setShowImage(true);
    }
  }, [defaultImage, getValues]);

  return (
    <img
      className={showImage ? (isTeslaAsset ? `imageCar tesla-static-asset` : `imageCar`) : `imageCar imageCarLoading`}
      style={isLargeSize ? {} : { width: "unset" }}
      alt=""
      src={image}
    />
  );
};

export default function LeftBox() {
  const isLargeSize = useMediaQuery({ minWidth: RESPONSIVE.MIN_WIDTH_LARGE });

  return (
    <>
      {isLargeSize ? (
        <Col md={6} className="d-flex p-0 flex-column">
          <Col
            className="car-wrapper p-0"
            style={{ height: 230, width: "80%" }}
          >
            <ImageComponent isLargeSize={isLargeSize} />
          </Col>
        </Col>
      ) : (
        <div
          className="car-wrapper"
          style={{ height: 180, justifyContent: "center" }}
        >
          <ImageComponent isLargeSize={isLargeSize} />
        </div>
      )}
    </>
  );
}
