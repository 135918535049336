import { FormBlock, FormButton, FormInput } from "components";
import DatepickerComponent from "components/datePicker/datePickerComponent";
import InputRow from "components/InputRow";
import Layout from "components/Layout";
import LockIcon from "components/LockIcon";
import { CUSTOM_STYLE, RESPONSIVE } from "constant";
import { useCountry } from "context/country";
import { useLand } from "context/land";
import _ from "lodash";
import { useEffect, useRef, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { Controller, useFormContext } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { useMediaQuery } from "react-responsive";
import Select from "react-select";
import { useLocale } from "../../../context/locale";

const radioStyle = {
  filter: "hue-rotate(-140deg)",
};

export default function People({ stepperOptions, onNextStep, onBackStep }) {
  const { formatMessage } = useIntl();
  const {
    trigger,
    watch,
    formState: { errors },
    getValues,
    control,
    setValue,
  } = useFormContext();
  const { countries = [] } = watch();
  const { locale } = useLocale();
  const isMobile = useMediaQuery({ maxWidth: RESPONSIVE.MAX_WIDTH_SMALL });
  const [telephone, setTelephone] = useState({
    mobile: watch("mobile"),
    private: watch("private"),
    business: watch("business"),
    otherPhone: watch("otherPhone"),
    defaultTelephone: "",
  });
  // const teleRef = useRef();
  const isTeleValid =
    watch("mobile").length > 4 ||
    watch("private").length > 4 ||
    watch("business").length > 4 ||
    watch("otherPhone").length > 4 ||
    watch("telephoneNumber").length > 4;
  const [isShowTeleError, setShowTeleError] = useState(false);
  const { countryContext } = useCountry();
  const { land } = useLand();

  // autofill
  const [autofill, setAutofill] = useState("Nein");

  // Validate page and go to next page
  const _onNextStep = async () => {
    if (watch("PersonWeitereTelefonnummer") === "mobil") {
      setTelephone({ ...telephone, mobil: watch("telephoneNumber") });
    } else if (watch("PersonWeitereTelefonnummer") === "privat") {
      setTelephone({ ...telephone, privat: watch("telephoneNumber") });
    } else if (watch("PersonWeitereTelefonnummer") === "geschaeft") {
      setTelephone({ ...telephone, geschaeft: watch("telephoneNumber") });
    } else if (watch("PersonWeitereTelefonnummer") === "weitereTelefonnummer") {
      setTelephone({
        ...telephone,
        weitereTelefonnummer: watch("telephoneNumber"),
      });
    }
    const isValid = await stepperOptions.triggerForm(3);
    if (!isTeleValid) {
      setShowTeleError(true);
    }
    if (
      (!_.isEqual(_.omit(telephone, ["defaultTelephone"]), {
        mobile: "",
        private: "",
        business: "",
        otherPhone: "",
      }) ||
        watch("telephoneNumber")) &&
      isValid &&
      isTeleValid &&
      (getValues("controlHolder") === "1" || "2a" || "2b" || "2c" || "3")
    ) {
      onNextStep();
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === " ") {
      e.preventDefault();
    }
  };

  useEffect(() => {
    if (getValues("controlHolder") === "2") {
      setValue("controlHolder", "2a");
    }
  }, [getValues("controlHolder")]);

  // Validate page and go to previous page
  const _onBackStep = async () => {
    await stepperOptions.triggerForm(3);
    onBackStep();
  };

  // setValue by autofill
  useEffect(() => {
    if (autofill === "Ja") {
      setValue("salutationKInh1", getValues("salutation"));
      setValue("firstNameKInh1", getValues("firstName"));
      setValue("lastNameKInh1", getValues("lastName"));
      setValue("birthdayKInh1", getValues("birthday"));
      setValue("nationalityKInh1", getValues("nationality"));
      setValue("streetKInh1", getValues("street"));
      setValue("numberKInh1", getValues("number"));
      setValue("doorNumberKInh1", getValues("doorNumber"));
      setValue("postcodeKInh1", getValues("zip"));
      setValue("placeKInh1", getValues("place"));
      setValue("countryKInh1", getValues("country"));
    } else {
      setValue("salutationKInh1", "");
      setValue("firstNameKInh1", "");
      setValue("lastNameKInh1", "");
      setValue("birthdayKInh1", "");
      setValue("streetKInh1", "");
      setValue("numberKInh1", "");
      setValue("doorNumberKInh1", "");
      setValue("postcodeKInh1", "");
      setValue("placeKInh1", "");
    }
  }, [autofill, getValues, setValue]);

  // Scroll view to top
  useEffect(() => {
    document
      .getElementsByClassName(
        `${isMobile ? "MuiStepLabel-active" : "active"}`
      )[0]
      .scrollIntoView({ behavior: "smooth", inline: "center" });
    window.scrollTo(0, 0);
  }, []);

  // Update country language when locale is changed
  useEffect(() => {
    const countries = (countryContext?.countryList || []).map((item) => ({
      value: item.isoCode,
      label: item[locale],
      sequence: item["sequence" + locale.toUpperCase()],
    }));
    const lands = (land?.countryList || []).map((item) => ({
      value: item.isoCode,
      label: item[locale],
      sequence: item["sequence" + locale.toUpperCase()],
    }));
    countries.sort((a, b) => a.sequence - b.sequence);
    const defaultCountryISOCode = "CH";
    const defaultCountry =
      countries.find((item) => item.value === defaultCountryISOCode) || {};
    const translatedCountry = (name) =>
      countries.find((item) => item.value === getValues(name).value) || {};
    const defaultLand =
      lands.find((item) => item.value === getValues("land").value) || {};
    setValue("defaultCountry", defaultCountry);
    setValue("defaultLand", defaultLand);
    setValue("countries", countries);
    setValue("lands", lands);
    setValue("land", defaultLand);
    setValue("nationality", translatedCountry("nationality"));
    setValue("country", translatedCountry("country"));
    setValue("nationality2", translatedCountry("nationality2"));
    setValue("country2", translatedCountry("country2"));
    setValue("nationalityDriver", translatedCountry("nationalityDriver"));
    setValue("countryKInh1", translatedCountry("countryKInh1"));
    setValue("countryKInh2", translatedCountry("countryKInh2"));
  }, [locale]);

  // Update phone number values
  useEffect(() => {
    if (watch("personPhone") === "mobile") {
      setValue("mobile", telephone["mobile"]);
    } else if (watch("personPhone") === "private") {
      setValue("private", telephone["private"]);
    } else if (watch("personPhone") === "business") {
      setValue("business", telephone["business"]);
    } else if (watch("personPhone") === "otherPhone") {
      setValue("otherPhone", telephone["otherPhone"]);
    }
  }, [telephone]);

  // const radioButton = (".browsers :checkbox[required]") => {
  //   requiredCheckboxes.change(function () {
  //     if (requiredCheckboxes.is(":checked")) {
  //       requiredCheckboxes.removeAttr("required");
  //     } else {
  //       requiredCheckboxes.attr("required", "required");
  //     }
  //   });
  // };

  const handleInputChange = (value, name) => {
    const newVal = value.replace(/\d+/g, "");
    setValue(name, newVal);
  };

  return (
    <>
      <Layout
        stepperOptions={stepperOptions}
        onNextStep={_onNextStep}
        onBackStep={_onBackStep}
        footer={{ icon: <LockIcon /> }}>
        <FormBlock title={formatMessage({ id: "contact.title.contactPerson" })}>
          <div
            style={{
              fontFamily: "Roboto",
              fontWeight: "bold",
              fontSize: "17px",
            }}>
            {formatMessage({ id: "contact.title.contactPersonSubHeadline" })}
          </div>

          <Controller
            name="salutation"
            control={control}
            defaultValue={getValues("salutation")}
            render={({ field }) => {
              return (
                <FormButton
                  isRequired
                  isValid={field.value}
                  isShowError={errors.salutation && errors.salutation.message}
                  label={formatMessage({ id: "contact.input.salutation" })}
                  buttons={[
                    {
                      icon: "icon-male",
                      label: formatMessage({ id: "contact.icon.mr" }),
                      value: "Herr",
                    },
                    {
                      icon: "icon-female",
                      label: formatMessage({ id: "contact.icon.mrs" }),
                      value: "Frau",
                    },
                  ]}
                  onClick={(e, value) => {
                    field.onChange(value);
                  }}
                  {...field}
                />
              );
            }}
          />

          <Controller
            name="firstName"
            control={control}
            defaultValue={getValues("firstName")}
            render={({ field }) => {
              return (
                <InputRow
                  isValid={watch("firstName") && !errors.firstName}
                  isShowError={!!errors.firstName?.message}
                  label={formatMessage({ id: "contact.input.firstName" })}
                  isRequired>
                  <Form.Control
                    required
                    {...field}
                    onChange={(e) =>
                      handleInputChange(e.target.value, field.name)
                    }
                  />
                </InputRow>
              );
            }}
          />

          <Controller
            name="lastName"
            control={control}
            defaultValue={getValues("lastName")}
            render={({ field }) => {
              return (
                <InputRow
                  isValid={watch("lastName") && !errors.lastName}
                  isShowError={!!errors.lastName?.message}
                  label={formatMessage({ id: "contact.input.lastName" })}
                  isRequired>
                  <Form.Control
                    required
                    {...field}
                    onChange={(e) =>
                      handleInputChange(e.target.value, field.name)
                    }
                  />
                </InputRow>
              );
            }}
          />

          <Controller
            name="birthday"
            control={control}
            render={({ field: { onChange, onBlur, value, ref } }) => {
              return (
                <>
                  <InputRow
                    label={formatMessage({
                      id: "contact.input.birthday",
                    })}
                    isRequired
                    isValid={value}
                    tooltip={formatMessage({
                      id: "common.date.format",
                    })}
                    isShowError={errors.birthday && errors.birthday.message}>
                    <div className="row d-flex align-items-center">
                      <Col md={4}>
                        <DatepickerComponent
                          isDoB
                          //value={value}
                          value={value ? new Date(value) : value}
                          onChange={(e) => {
                            onChange(e);
                            onBlur(e); //TODO: Trick for validate (mode: "onBlur")
                          }}
                          onBlur={onBlur}
                        />
                      </Col>
                    </div>
                  </InputRow>
                </>
              );
            }}
          />

          <Controller
            name="nationality"
            isClearable
            control={control}
            render={({ field }) => {
              return (
                <InputRow
                  isShowError={errors.nationality && errors.nationality.message}
                  label={formatMessage({ id: "contact.input.nationality" })}
                  isRequired
                  isValid={field.value}>
                  <Select
                    placeholder={formatMessage({
                      id: "common.date.year",
                    })}
                    {...field}
                    isSearchable
                    options={countries.map((e) =>
                      e.value === ""
                        ? { value: e.value, label: e.label, isDisabled: true }
                        : e
                    )}
                    styles={CUSTOM_STYLE}
                  />
                </InputRow>
              );
            }}
          />

          <InputRow
            label={
              <>
                {/* <FormattedMessage
                  id="contact.input.street"
                /> */}
                <FormattedMessage id="data.input.addressStreet" />
                <sup class="required"> *</sup>
                {" / "}
                <FormattedMessage id="data.input.addressNumber" />
                <sup class="required"> *</sup>
                {" / "}
                <FormattedMessage id="data.input.addressDoor" />
              </>
            }
            isValid={
              watch("street") &&
              watch("number") &&
              !errors.street &&
              !errors.number
            }
            isShowError={
              errors.street
                ? errors.street.message
                : errors.number
                ? errors.number.message
                : null
            }>
            <Row>
              <Col md={8} sm={8} xs={8}>
                <Controller
                  name="street"
                  control={control}
                  defaultValue={getValues("street")}
                  render={({ field }) => {
                    return (
                      <Form.Control
                        className="input-shadow"
                        required
                        {...field}
                        onChange={(event) => {
                          setValue("street", event.target.value);
                        }}
                        onBlur={async () => {
                          field.onBlur();
                        }}
                      />
                    );
                  }}
                />
              </Col>
              <Col md={2} sm={2} xs={2}>
                <Controller
                  name="number"
                  control={control}
                  defaultValue={getValues("number")}
                  render={({ field }) => {
                    return (
                      <Form.Control
                        className="input-shadow"
                        required
                        {...field}
                        onChange={(event) => {
                          setValue("number", event.target.value);
                        }}
                        onBlur={async () => {
                          field.onBlur();
                        }}
                      />
                    );
                  }}
                />
              </Col>
              <Col md={2} sm={2} xs={2}>
                <Controller
                  name="doorNumber"
                  control={control}
                  defaultValue={getValues("doorNumber")}
                  render={({ field }) => {
                    return (
                      <Form.Control
                        className="input-shadow"
                        {...field}
                        onChange={(event) => {
                          setValue("doorNumber", event.target.value);
                        }}
                        onBlur={async () => {
                          field.onBlur();
                        }}
                      />
                    );
                  }}
                />
              </Col>
            </Row>
            <Row></Row>
          </InputRow>
          <InputRow
            label={formatMessage({ id: "contact.input.postcode" })}
            isValid={
              watch("zip") && watch("place") && !errors.zip && !errors.place
            }
            isShowError={
              errors.zip
                ? errors.zip.message
                : errors.place
                ? errors.place.message
                : null
            }
            isRequired>
            <Row>
              <Col md={4} xs={4} sm={4}>
                <Controller
                  name="zip"
                  control={control}
                  defaultValue={getValues("zip")}
                  render={({ field }) => {
                    return (
                      <Form.Control
                        inputMode="numeric"
                        className="input-shadow"
                        required
                        {...field}
                        onChange={(event) => {
                          event.preventDefault();
                          if (!_.isNaN(Number(event.target.value))) {
                            setValue("zip", event.target.value);
                          }
                        }}
                        onBlur={async () => {
                          field.onBlur();
                        }}
                      />
                    );
                  }}
                />
              </Col>
              <Col md={8} xs={8} sm={8}>
                <Controller
                  name="place"
                  control={control}
                  defaultValue={getValues("place")}
                  render={({ field }) => {
                    return (
                      <Form.Control
                        className="input-shadow"
                        required
                        {...field}
                      />
                    );
                  }}
                />
              </Col>
            </Row>
          </InputRow>

          <Controller
            name="country"
            isClearable
            control={control}
            render={({ field }) => {
              return (
                <InputRow
                  isShowError={errors.country && errors.country.message}
                  label={formatMessage({ id: "contact.input.country" })}
                  isRequired
                  isValid={field.value}>
                  <Select
                    placeholder={formatMessage({
                      id: "common.date.year",
                    })}
                    {...field}
                    isSearchable
                    options={countries.map((e) =>
                      e.value === ""
                        ? { value: e.value, label: e.label, isDisabled: true }
                        : e
                    )}
                    styles={CUSTOM_STYLE}
                  />
                </InputRow>
              );
            }}
          />
          <Controller
            name="role"
            control={control}
            defaultValue={getValues("role")}
            render={({ field }) => {
              return (
                <InputRow
                  isValid={watch("role") && !errors.role}
                  isShowError={!!errors.role?.message}
                  label={formatMessage({ id: "contact.input.role" })}
                  tooltip={formatMessage({ id: "contact.input.role.tooltip" })}
                  isRequired>
                  <Form.Control required {...field} />
                </InputRow>
              );
            }}
          />

          <Controller
            name="personPhone"
            control={control}
            defaultValue={getValues("personPhone")}
            render={({ field }) => {
              return (
                <Col>
                  <FormButton
                    isShowStatus={false}
                    isShowError={field.value === "defaultTelephone"}
                    isValid={field.value !== "defaultTelephone"}
                    buttons={[
                      {
                        icon: "icon-telephone",
                        label: formatMessage({ id: "contact.icon.business" }),
                        value: "business",
                      },
                      {
                        icon: "icon-mobile",
                        label: formatMessage({ id: "contact.icon.mobile" }),
                        value: "mobile",
                      },
                      {
                        icon: "icon-phone",
                        label: formatMessage({ id: "contact.icon.private" }),
                        value: "private",
                      },
                      {
                        icon: "icon-plus",
                        label: formatMessage({ id: "contact.icon.otherPhone" }),
                        value: "otherPhone",
                      },
                    ]}
                    onClick={(e, value) => {
                      if (
                        _.isEqual(watch("personPhone"), "defaultTelephone") &&
                        !_.isEmpty(telephone[watch("personPhone")])
                      ) {
                        if (value === "mobile") {
                          setTelephone({
                            ...telephone,
                            mobile: telephone[watch("personPhone")],
                          });
                        } else if (value === "private") {
                          setTelephone({
                            ...telephone,
                            private: telephone[watch("personPhone")],
                          });
                        } else if (value === "business") {
                          setTelephone({
                            ...telephone,
                            business: telephone[watch("personPhone")],
                          });
                        } else if (value === "otherPhone") {
                          setTelephone({
                            ...telephone,
                            otherPhone: telephone[watch("personPhone")],
                          });
                        }
                      }
                      field.onChange(value);
                      setTelephone((val) => {
                        return {
                          ...val,
                          defaultTelephone: "",
                        };
                      });

                      trigger(["mobile", "private", "business", "otherPhone"], {
                        shouldFocus: true,
                      });
                    }}
                    {...field}
                  />
                </Col>
              );
            }}
          />
          {/* <InputRow
            isValid={isTeleValid}
            isShowError={!isTeleValid && isShowTeleError}
            label={formatMessage({ id: "contact.input.phoneNumber" })}
            isRequired
            tooltip={formatMessage({ id: "contact.input.teleformat" })}>
            <Row className="d-flex align-items-center">
              <Col md={8} xs={12}>
                <Controller
                  name={watch("personPhone")}
                  control={control}
                  render={({ field }) => {
                    return (
                      <>
                        <Form.Control
                          inputMode="numeric"
                          className="input-shadow"
                          required
                          {...field}
                          value={telephone[watch("personPhone")]}
                          onChange={(event) => {
                            event.preventDefault();
                            if (!_.isNaN(Number(event.target.value))) {
                              if (getValues("personPhone") === "mobile") {
                                setTelephone({
                                  ...telephone,
                                  mobile: event.target.value,
                                });
                              } else if (
                                getValues("personPhone") === "private"
                              ) {
                                setTelephone({
                                  ...telephone,
                                  private: event.target.value,
                                });
                              } else if (
                                getValues("personPhone") === "business"
                              ) {
                                setTelephone({
                                  ...telephone,
                                  business: event.target.value,
                                });
                              } else if (
                                getValues("personPhone") === "otherPhone"
                              ) {
                                setTelephone({
                                  ...telephone,
                                  otherPhone: event.target.value,
                                });
                              } else if (
                                getValues("personPhone") === "defaultTelephone"
                              ) {
                                setTelephone({
                                  ...telephone,
                                  defaultTelephone: event.target.value,
                                });
                              }
                            }
                          }}
                          onKeyDown={handleKeyDown}
                          ref={teleRef}
                          onBlur={() => {
                            if (!isTeleValid) {
                              setShowTeleError(true);
                            }
                          }}
                        />
                      </>
                    );
                  }}
                />
              </Col>
            </Row>
          </InputRow> */}
          <InputRow
            label={<FormattedMessage id="contact.input.phoneNumber" />}
            tooltip={<FormattedMessage id="contact.input.teleformat" />}
            isValid={watch("telephoneNumber") && !errors.telephoneNumber}
            isShowError={
              errors.telephoneNumber ? errors.telephoneNumber.message : null
            }
            isRequired>
            <Row className="d-flex align-items-center">
              <Col md={8} xs={12}>
                <Controller
                  name="telephoneNumber"
                  control={control}
                  defaultValue={getValues("telephoneNumber")}
                  render={({ field }) => {
                    return (
                      <Form.Control
                        inputMode="tel"
                        className="input-shadow"
                        required
                        {...field}
                        onChange={(event) => {
                          event.preventDefault();
                          setValue(
                            "telephoneNumber",
                            event.target.value.replace(/[^0-9()+-.]/gi, "")
                          );
                        }}
                        onBlur={async () => {
                          field.onBlur();
                        }}
                      />
                    );
                  }}
                />
              </Col>
            </Row>
          </InputRow>
          <Controller
            name="email"
            control={control}
            render={({ field }) => {
              return (
                <FormInput
                  isShowError={!!errors.email?.message}
                  isValid={watch("email") && !errors.email}
                  isRequired
                  label={formatMessage({ id: "contact.input.email" })}
                  {...field}
                />
              );
            }}
          />
        </FormBlock>
        <Controller
          name="2ndAuthority"
          control={control}
          defaultValue={getValues("2ndAuthority")}
          render={({ field }) => {
            return (
              <FormButton
                isLeft
                isRequired
                isValid={field.value}
                isShowError={
                  errors["2ndAuthority"] && errors["2ndAuthority"].message
                }
                label={formatMessage({ id: "contact.button.2ndAuthority" })}
                tooltip={formatMessage({
                  id: "contact.button.2ndAuthority.tooltip",
                })}
                buttons={[
                  {
                    text: formatMessage({ id: "contact.button.yes" }),
                    value: "Ja",
                  },
                  {
                    text: formatMessage({ id: "contact.button.no" }),
                    value: "Nein",
                  },
                ]}
                onClick={(e, value) => {
                  field.onChange(value);
                }}
                {...field}
              />
            );
          }}
        />
        {watch("2ndAuthority") === "Nein" && (
          <FormBlock
            title={formatMessage({ id: "contact.title.contactPerson2" })}>
            <Controller
              name="salutation2"
              control={control}
              defaultValue={getValues("salutation2")}
              render={({ field }) => {
                return (
                  <FormButton
                    isRequired
                    isValid={field.value}
                    isShowError={
                      errors.salutation2 && errors.salutation2.message
                    }
                    label={formatMessage({ id: "contact.input.salutation" })}
                    buttons={[
                      {
                        icon: "icon-male",
                        label: formatMessage({ id: "contact.icon.mr" }),
                        value: "Herr",
                      },
                      {
                        icon: "icon-female",
                        label: formatMessage({ id: "contact.icon.mrs" }),
                        value: "Frau",
                      },
                    ]}
                    onClick={(e, value) => {
                      field.onChange(value);
                    }}
                    {...field}
                  />
                );
              }}
            />
            <Controller
              name="firstName2"
              control={control}
              defaultValue={getValues("firstName2")}
              render={({ field }) => {
                return (
                  <InputRow
                    isValid={watch("firstName2") && !errors.firstName2}
                    isShowError={!!errors.firstName2?.message}
                    label={formatMessage({ id: "contact.input.firstName" })}
                    isRequired>
                    <Form.Control
                      required
                      {...field}
                      onChange={(e) =>
                        handleInputChange(e.target.value, field.name)
                      }
                    />
                  </InputRow>
                );
              }}
            />
            <Controller
              name="lastName2"
              control={control}
              defaultValue={getValues("lastName2")}
              render={({ field }) => {
                return (
                  <InputRow
                    isValid={watch("lastName2") && !errors.lastName2}
                    isShowError={!!errors.lastName2?.message}
                    label={formatMessage({ id: "contact.input.lastName" })}
                    isRequired>
                    <Form.Control
                      required
                      {...field}
                      onChange={(e) =>
                        handleInputChange(e.target.value, field.name)
                      }
                    />
                  </InputRow>
                );
              }}
            />
            <Controller
              name="birthday2"
              control={control}
              render={({ field: { onChange, onBlur, value, ref } }) => {
                return (
                  <>
                    <InputRow
                      label={formatMessage({
                        id: "contact.input.birthday2",
                      })}
                      isRequired
                      isValid={value}
                      tooltip={formatMessage({
                        id: "common.date.format",
                      })}
                      isShowError={
                        errors.birthday2 && errors.birthday2.message
                      }>
                      <div className="row d-flex align-items-center">
                        <Col md={4}>
                          <DatepickerComponent
                            isDoB
                            //value={value}
                            value={value ? new Date(value) : value}
                            onChange={(e) => {
                              onChange(e);
                              onBlur(e); //TODO: Trick for validate (mode: "onBlur")
                            }}
                            onBlur={onBlur}
                          />
                        </Col>
                      </div>
                    </InputRow>
                  </>
                );
              }}
            />
            <Controller
              name="nationality2"
              isClearable
              control={control}
              render={({ field }) => {
                return (
                  <InputRow
                    isShowError={
                      errors.nationality2 && errors.nationality2.message
                    }
                    label={formatMessage({ id: "contact.input.nationality" })}
                    isRequired
                    isValid={field.value}>
                    <Select
                      placeholder={formatMessage({
                        id: "common.date.year",
                      })}
                      {...field}
                      isSearchable
                      options={countries.map((e) =>
                        e.value === ""
                          ? { value: e.value, label: e.label, isDisabled: true }
                          : e
                      )}
                      styles={CUSTOM_STYLE}
                    />
                  </InputRow>
                );
              }}
            />
            <InputRow
              label={
                <>
                  {/* <FormattedMessage
                    id="contact.input.street"
                  /> */}
                  <FormattedMessage id="data.input.addressStreet" />
                  <sup class="required"> *</sup>
                  {" / "}
                  <FormattedMessage id="data.input.addressNumber" />
                  <sup class="required"> *</sup>
                  {" / "}
                  <FormattedMessage id="data.input.addressDoor" />
                </>
              }
              isValid={
                watch("street2") &&
                watch("number2") &&
                !errors.street2 &&
                !errors.number2
              }
              isShowError={
                errors.street2
                  ? errors.street2.message
                  : errors.number2
                  ? errors.number2.message
                  : null
              }>
              <Row>
                <Col md={8} sm={8} xs={8}>
                  <Controller
                    name="street2"
                    control={control}
                    defaultValue={getValues("street2")}
                    render={({ field }) => {
                      return (
                        <Form.Control
                          className="input-shadow"
                          required
                          {...field}
                          onChange={(event) => {
                            setValue("street2", event.target.value);
                          }}
                          onBlur={async () => {
                            field.onBlur();
                          }}
                        />
                      );
                    }}
                  />
                </Col>
                <Col md={2} sm={2} xs={2}>
                  <Controller
                    name="number2"
                    control={control}
                    defaultValue={getValues("number2")}
                    render={({ field }) => {
                      return (
                        <Form.Control
                          className="input-shadow"
                          required
                          {...field}
                          onChange={(event) => {
                            setValue("number2", event.target.value);
                          }}
                          onBlur={async () => {
                            field.onBlur();
                          }}
                        />
                      );
                    }}
                  />
                </Col>
                <Col md={2} sm={2} xs={2}>
                  <Controller
                    name="doorNumber2"
                    control={control}
                    defaultValue={getValues("doorNumber2")}
                    render={({ field }) => {
                      return (
                        <Form.Control
                          className="input-shadow"
                          {...field}
                          onChange={(event) => {
                            setValue("doorNumber2", event.target.value);
                          }}
                          onBlur={async () => {
                            field.onBlur();
                          }}
                        />
                      );
                    }}
                  />
                </Col>
              </Row>
              <Row></Row>
            </InputRow>
            <InputRow
              label={formatMessage({ id: "contact.input.postcode" })}
              isRequired
              isValid={
                watch("zip2") &&
                watch("place2") &&
                !errors.zip2 &&
                !errors.place2
              }
              isShowError={
                errors.zip2
                  ? errors.zip2.message
                  : errors.place2
                  ? errors.place2.message
                  : null
              }>
              <Row>
                <Col md={4} xs={4} sm={4}>
                  <Controller
                    name="zip2"
                    control={control}
                    defaultValue={getValues("zip2")}
                    render={({ field }) => {
                      return (
                        <Form.Control
                          inputMode="numeric"
                          className="input-shadow"
                          required
                          {...field}
                          onChange={(event) => {
                            event.preventDefault();
                            if (!_.isNaN(Number(event.target.value))) {
                              setValue("zip2", event.target.value);
                            }
                          }}
                          onBlur={async () => {
                            field.onBlur();
                          }}
                        />
                      );
                    }}
                  />
                </Col>
                <Col md={8} xs={8} sm={8}>
                  <Controller
                    name="place2"
                    control={control}
                    defaultValue={getValues("place2")}
                    render={({ field }) => {
                      return (
                        <Form.Control
                          className="input-shadow"
                          required
                          {...field}
                        />
                      );
                    }}
                  />
                </Col>
              </Row>
              <Row></Row>
            </InputRow>
            <Controller
              name="country2"
              isClearable
              control={control}
              render={({ field }) => {
                return (
                  <InputRow
                    isShowError={errors.country2 && errors.country2.message}
                    label={formatMessage({ id: "contact.input.country" })}
                    isRequired
                    isValid={field.value}>
                    <Select
                      placeholder={formatMessage({
                        id: "common.date.year",
                      })}
                      {...field}
                      isSearchable
                      options={countries.map((e) =>
                        e.value === ""
                          ? { value: e.value, label: e.label, isDisabled: true }
                          : e
                      )}
                      styles={CUSTOM_STYLE}
                    />
                  </InputRow>
                );
              }}
            />
            <Controller
              name="MDRole2"
              control={control}
              defaultValue={getValues("MDRole2")}
              render={({ field }) => {
                return (
                  <InputRow
                    isValid={watch("MDRole2") && !errors.MDRole2}
                    isShowError={!!errors.MDRole2?.message}
                    label={formatMessage({ id: "contact.input.MDRole2" })}
                    isRequired>
                    <Form.Control required {...field} />
                  </InputRow>
                );
              }}
            />
            <Controller
              name="email2"
              control={control}
              render={({ field }) => {
                return (
                  <FormInput
                    isShowError={!!errors.email2?.message}
                    isValid={watch("email2") && !errors.email2}
                    isRequired
                    label={formatMessage({ id: "contact.input.email" })}
                    {...field}
                  />
                );
              }}
            />
          </FormBlock>
        )}
        <Controller
          name="driver"
          control={control}
          defaultValue={getValues("driver")}
          render={({ field }) => {
            return (
              <FormButton
                isLeft
                isRequired
                isValid={field.value}
                isShowError={errors.driver && errors.driver.message}
                label={formatMessage({ id: "contact.button.driver" })}
                tooltip={formatMessage({ id: "contact.button.driver.tooltip" })}
                buttons={[
                  {
                    text: formatMessage({ id: "contact.button.yes" }),
                    value: "Ja",
                  },
                  {
                    text: formatMessage({ id: "contact.button.no" }),
                    value: "Nein",
                  },
                ]}
                onClick={(e, value) => {
                  field.onChange(value);
                }}
                {...field}
              />
            );
          }}
        />
        {watch("driver") === "Nein" && (
          <FormBlock title={formatMessage({ id: "contact.title.driver" })}>
            <Controller
              name="salutationDriver"
              control={control}
              defaultValue={getValues("salutationDriver")}
              render={({ field }) => {
                return (
                  <FormButton
                    isRequired
                    isValid={field.value}
                    isShowError={
                      errors.salutationDriver && errors.salutationDriver.message
                    }
                    label={formatMessage({ id: "contact.input.salutation" })}
                    buttons={[
                      {
                        icon: "icon-male",
                        label: formatMessage({ id: "contact.icon.mr" }),
                        value: "Herr",
                      },
                      {
                        icon: "icon-female",
                        label: formatMessage({ id: "contact.icon.mrs" }),
                        value: "Frau",
                      },
                    ]}
                    onClick={(e, value) => {
                      field.onChange(value);
                    }}
                    {...field}
                  />
                );
              }}
            />
            <Controller
              name="firstNameDriver"
              control={control}
              defaultValue={getValues("firstNameDriver")}
              render={({ field }) => {
                return (
                  <InputRow
                    isValid={
                      watch("firstNameDriver") && !errors.firstNameDriver
                    }
                    isShowError={!!errors.firstNameDriver?.message}
                    label={formatMessage({ id: "contact.input.firstName" })}
                    isRequired>
                    <Form.Control
                      required
                      {...field}
                      onChange={(e) =>
                        handleInputChange(e.target.value, field.name)
                      }
                    />
                  </InputRow>
                );
              }}
            />
            <Controller
              name="lastNameDriver"
              control={control}
              defaultValue={getValues("lastNameDriver")}
              render={({ field }) => {
                return (
                  <InputRow
                    isValid={watch("lastNameDriver") && !errors.lastNameDriver}
                    isShowError={!!errors.lastNameDriver?.message}
                    label={formatMessage({ id: "contact.input.lastName" })}
                    isRequired>
                    <Form.Control
                      required
                      {...field}
                      onChange={(e) =>
                        handleInputChange(e.target.value, field.name)
                      }
                    />
                  </InputRow>
                );
              }}
            />
            <Controller
              name="birthdayDriver"
              control={control}
              defaultValue={getValues("birthdayDriver")}
              render={({ field: { onChange, onBlur, value, ref } }) => {
                return (
                  <>
                    <InputRow
                      label={formatMessage({
                        id: "contact.input.birthday",
                      })}
                      isRequired
                      isValid={
                        watch("birthdayDriver") && !errors.birthdayDriver
                      }
                      tooltip={formatMessage({
                        id: "common.date.format",
                      })}
                      isShowError={
                        errors.birthdayDriver && errors.birthdayDriver.message
                      }>
                      <div className="row d-flex align-items-center">
                        <Col md={4}>
                          <DatepickerComponent
                            isDoB
                            //value={value}
                            value={value ? new Date(value) : value}
                            onChange={(e) => {
                              onChange(e);
                              onBlur(e); //TODO: Trick for validate (mode: "onBlur")
                            }}
                            onBlur={onBlur}
                          />
                        </Col>
                      </div>
                    </InputRow>
                  </>
                );
              }}
            />
            <Controller
              name="nationalityDriver"
              isClearable
              control={control}
              // defaultValue={getValues("nationalityDriver")}
              render={({ field }) => {
                return (
                  <InputRow
                    isShowError={
                      errors.nationalityDriver &&
                      errors.nationalityDriver.message
                    }
                    label={formatMessage({ id: "contact.input.nationality" })}
                    isRequired
                    isValid={
                      watch("nationalityDriver") && !errors.nationalityDriver
                    }>
                    <Select
                      placeholder={formatMessage({
                        id: "common.date.year",
                      })}
                      {...field}
                      isSearchable
                      options={countries.map((e) =>
                        e.value === ""
                          ? { value: e.value, label: e.label, isDisabled: true }
                          : e
                      )}
                      styles={CUSTOM_STYLE}
                    />
                  </InputRow>
                );
              }}
            />
          </FormBlock>
        )}
        <FormBlock title={formatMessage({ id: "contact.title.controlHolder" })}>
          <div
            style={{
              fontFamily: "Roboto",
              fontWeight: "bold",
              fontSize: "17px",
              marginBottom: "1rem",
              marginTop: isMobile ? "0.5rem" : "-1rem",
            }}>
            {formatMessage({ id: "contact.title.controlHolderSubHeadline" })}
          </div>
          <Controller
            name="controlHolder"
            control={control}
            isRequired
            defaultValue={getValues("controlHolder")}
            render={({ field }) => {
              console.log("<<<<<<>>>>>>", field, getValues("controlHolder"));
              return (
                <>
                  <Form {...field}>
                    {["1", "2", "3"].map((value) => {
                      return value === "2" ? (
                        <>
                          <div
                            key={`inline-radio`}
                            className="mb-3"
                            style={radioStyle}
                            required>
                            <Form.Check
                              inline
                              label={formatMessage({
                                id: `contact.title.checkBox${value}`,
                              })}
                              name="group1"
                              type="radio"
                              id={`inline-radio-${value}`}
                              value={value}
                            />
                          </div>
                          {["2", "2a", "2b", "2c"].includes(field.value) ? (
                            <div required className="ml-4">
                              {formatMessage({
                                id: `contact.text.otherHolders`,
                              })}
                              {["2a", "2b", "2c"].map((e) => (
                                <div
                                  required
                                  key={`inline-radio`}
                                  className="mb-3"
                                  style={radioStyle}>
                                  <Form.Check
                                    inline
                                    label={formatMessage({
                                      id: `contact.title.checkBox${e}`,
                                    })}
                                    name="group1"
                                    type="radio"
                                    id={`inline-radio-${e}`}
                                    value={e}
                                    checked={field.value === e}
                                  />
                                </div>
                              ))}
                            </div>
                          ) : null}
                        </>
                      ) : (
                        <div
                          key={`inline-radio`}
                          className="mb-3"
                          style={radioStyle}>
                          <Form.Check
                            inline
                            label={
                              value === "1"
                                ? `${formatMessage({
                                    id: `contact.title.checkBox${value}`,
                                  })} ${formatMessage({
                                    id: `contact.title.checkBox${value}P2`,
                                  })}`
                                : `${formatMessage({
                                    id: `contact.title.checkBox${value}`,
                                  })} ${formatMessage({
                                    id: `contact.title.checkBox${value}P2`,
                                  })}`
                            }
                            name="group1"
                            type="radio"
                            id={`inline-radio-${value}`}
                            value={value}
                          />
                        </div>
                      );
                    })}
                  </Form>
                </>
              );
            }}
          />
          {["2", "2a", "2b", "2c"].includes(watch("controlHolder")) && (
            <>
              <Controller
                name="autofillKInh1"
                control={control}
                defaultValue={getValues("autofillKInh1")}
                render={({ field }) => {
                  return (
                    <FormButton
                      isLeft
                      isRequired
                      isValid={field.value}
                      label={formatMessage({
                        id: "beneficialOwner.text.autofillKInh1",
                      })}
                      tooltip={formatMessage({
                        id: "beneficialOwner.text.autofillKInh1.tooltip",
                      })}
                      buttons={[
                        {
                          text: formatMessage({ id: "contact.button.yes" }),
                          value: "Ja",
                        },
                        {
                          text: formatMessage({ id: "contact.button.no" }),
                          value: "Nein",
                        },
                      ]}
                      onClick={(e, value) => {
                        field.onChange(value);
                        setAutofill(value);
                      }}
                      {...field}
                    />
                  );
                }}
              />
              <Controller
                name="salutationKInh1"
                control={control}
                defaultValue={getValues("salutationKInh1")}
                render={({ field }) => {
                  return (
                    <FormButton
                      isRequired
                      isValid={field.value}
                      isShowError={
                        errors.salutation && errors.salutation.message
                      }
                      label={formatMessage({
                        id: "contact.input.salutation",
                      })}
                      buttons={[
                        {
                          icon: "icon-male",
                          label: formatMessage({ id: "contact.icon.mr" }),
                          value: "Herr",
                        },
                        {
                          icon: "icon-female",
                          label: formatMessage({ id: "contact.icon.mrs" }),
                          value: "Frau",
                        },
                      ]}
                      onClick={(e, value) => {
                        field.onChange(value);
                      }}
                      {...field}
                    />
                  );
                }}
              />
              <Controller
                name="firstNameKInh1"
                control={control}
                defaultValue={getValues("firstNameKInh1")}
                render={({ field }) => {
                  return (
                    <InputRow
                      isValid={
                        watch("firstNameKInh1") && !errors.firstNameKInh1
                      }
                      isShowError={!!errors.firstNameKInh1?.message}
                      label={formatMessage({ id: "contact.input.firstName" })}
                      isRequired>
                      <Form.Control
                        required
                        {...field}
                        onChange={(e) =>
                          handleInputChange(e.target.value, field.name)
                        }
                      />
                    </InputRow>
                  );
                }}
              />
              <Controller
                name="lastNameKInh1"
                control={control}
                defaultValue={getValues("lastNameKInh1")}
                render={({ field }) => {
                  return (
                    <InputRow
                      isValid={watch("lastNameKInh1") && !errors.lastNameKInh1}
                      isShowError={!!errors.lastNameKInh1?.message}
                      label={formatMessage({ id: "contact.input.lastName" })}
                      isRequired>
                      <Form.Control
                        required
                        {...field}
                        onChange={(e) =>
                          handleInputChange(e.target.value, field.name)
                        }
                      />
                    </InputRow>
                  );
                }}
              />
              <Controller
                name="birthdayKInh1"
                control={control}
                render={({ field: { onChange, onBlur, value, ref } }) => {
                  return (
                    <>
                      <InputRow
                        label={formatMessage({
                          id: "contact.input.birthday",
                        })}
                        isRequired
                        isValid={value}
                        tooltip={formatMessage({ id: "common.date.format" })}
                        isShowError={
                          errors.birthdayKInh1 && errors.birthdayKInh1.message
                        }>
                        <div className="row d-flex align-items-center">
                          <Col md={4}>
                            <DatepickerComponent
                              isDoB
                              //value={value}
                              value={value ? new Date(value) : value}
                              onChange={(e) => {
                                onChange(e);
                                onBlur(e); //TODO: Trick for validate (mode: "onBlur")
                              }}
                              onBlur={onBlur}
                            />
                          </Col>
                        </div>
                      </InputRow>
                    </>
                  );
                }}
              />
              <Controller
                name="nationalityKInh1"
                isClearable
                control={control}
                render={({ field }) => {
                  return (
                    <InputRow
                      isShowError={
                        errors.nationality && errors.nationality.message
                      }
                      label={formatMessage({
                        id: "contact.input.nationality",
                      })}
                      isRequired
                      isValid={field.value}>
                      <Select
                        placeholder={formatMessage({
                          id: "common.date.year",
                        })}
                        {...field}
                        isSearchable
                        options={countries.map((e) =>
                          e.value === ""
                            ? {
                                value: e.value,
                                label: e.label,
                                isDisabled: true,
                              }
                            : e
                        )}
                        styles={CUSTOM_STYLE}
                      />
                    </InputRow>
                  );
                }}
              />
              <InputRow
                label={
                  <>
                    <FormattedMessage id="data.input.addressStreet" />
                    <sup class="required"> *</sup>
                    {" / "}
                    <FormattedMessage id="data.input.addressNumber" />
                    <sup class="required"> *</sup>
                    {" / "}
                    <FormattedMessage id="data.input.addressDoor" />
                  </>
                }
                isValid={
                  watch("streetKInh1") &&
                  watch("numberKInh1") &&
                  !errors.streetKInh1 &&
                  !errors.numberKInh1
                }
                isShowError={
                  errors.streetKInh1
                    ? errors.streetKInh1.message
                    : errors.numberKInh1
                    ? errors.numberKInh1.message
                    : null
                }>
                <Row>
                  <Col md={8} sm={8} xs={8}>
                    <Controller
                      name="streetKInh1"
                      control={control}
                      defaultValue={getValues("streetKInh1")}
                      render={({ field }) => {
                        return (
                          <Form.Control
                            className="input-shadow"
                            required
                            {...field}
                            onChange={(event) => {
                              setValue("streetKInh1", event.target.value);
                            }}
                            onBlur={async () => {
                              field.onBlur();
                            }}
                          />
                        );
                      }}
                    />
                  </Col>
                  <Col md={2} sm={2} xs={2}>
                    <Controller
                      name="numberKInh1"
                      control={control}
                      defaultValue={getValues("numberKInh1")}
                      render={({ field }) => {
                        return (
                          <Form.Control
                            className="input-shadow"
                            required
                            {...field}
                            onChange={(event) => {
                              setValue("numberKInh1", event.target.value);
                            }}
                            onBlur={async () => {
                              field.onBlur();
                            }}
                          />
                        );
                      }}
                    />
                  </Col>
                  <Col md={2} sm={2} xs={2}>
                    <Controller
                      name="doorNumberKInh1"
                      control={control}
                      defaultValue={getValues("doorNumberKInh1")}
                      render={({ field }) => {
                        return (
                          <Form.Control
                            className="input-shadow"
                            {...field}
                            onChange={(event) => {
                              setValue("doorNumberKInh1", event.target.value);
                            }}
                            onBlur={async () => {
                              field.onBlur();
                            }}
                          />
                        );
                      }}
                    />
                  </Col>
                </Row>
              </InputRow>
              <InputRow
                label={formatMessage({ id: "contact.input.postcode" })}
                isRequired
                isValid={
                  watch("postcodeKInh1") &&
                  watch("placeKInh1") &&
                  !errors.postcodeKInh1 &&
                  !errors.placeKInh1
                }
                isShowError={
                  errors.postcodeKInh1
                    ? errors.postcodeKInh1.message
                    : errors.placeKInh1
                    ? errors.placeKInh1.message
                    : null
                }>
                <Row>
                  <Col md={4} xs={4} sm={4}>
                    <Controller
                      name="postcodeKInh1"
                      control={control}
                      defaultValue={getValues("postcodeKInh1")}
                      render={({ field }) => {
                        return (
                          <Form.Control
                            inputMode="numeric"
                            className="input-shadow"
                            required
                            {...field}
                            onChange={(event) => {
                              event.preventDefault();
                              if (!_.isNaN(Number(event.target.value))) {
                                setValue("postcodeKInh1", event.target.value);
                              }
                            }}
                            onBlur={async () => {
                              field.onBlur();
                            }}
                          />
                        );
                      }}
                    />
                  </Col>
                  <Col md={8} xs={8} sm={8}>
                    <Controller
                      name="placeKInh1"
                      control={control}
                      defaultValue={getValues("placeKInh1")}
                      render={({ field }) => {
                        return (
                          <Form.Control
                            className="input-shadow"
                            required
                            {...field}
                          />
                        );
                      }}
                    />
                  </Col>
                </Row>
              </InputRow>
              <Controller
                name="countryKInh1"
                isClearable
                control={control}
                render={({ field }) => {
                  return (
                    <InputRow
                      isShowError={
                        errors.countryKInh1 && errors.countryKInh1.message
                      }
                      label={formatMessage({ id: "contact.input.country" })}
                      isRequired
                      isValid={field.value}>
                      <Select
                        placeholder={formatMessage({
                          id: "common.date.year",
                        })}
                        {...field}
                        isSearchable
                        options={countries.map((e) =>
                          e.value === ""
                            ? {
                                value: e.value,
                                label: e.label,
                                isDisabled: true,
                              }
                            : e
                        )}
                        styles={CUSTOM_STYLE}
                      />
                    </InputRow>
                  );
                }}
              />
              <Controller
                name="furtherPerson"
                control={control}
                defaultValue={getValues("furtherPerson")}
                render={({ field }) => {
                  return (
                    <FormButton
                      isLeft
                      isRequired
                      isValid={field.value}
                      isShowError={
                        errors.furtherPerson && errors.furtherPerson.message
                      }
                      label={formatMessage({
                        id: "contact.button.furtherPerson",
                      })}
                      buttons={[
                        {
                          text: formatMessage({ id: "contact.button.yes" }),
                          value: "Ja",
                        },
                        {
                          text: formatMessage({ id: "contact.button.no" }),
                          value: "Nein",
                        },
                      ]}
                      onClick={(e, value) => {
                        field.onChange(value);
                      }}
                      {...field}
                    />
                  );
                }}
              />
              {watch("furtherPerson") === "Ja" && (
                <>
                  <Controller
                    name="salutationKInh2"
                    control={control}
                    defaultValue={getValues("salutationKInh2")}
                    render={({ field }) => {
                      return (
                        <FormButton
                          isRequired
                          isValid={field.value}
                          isShowError={
                            errors.salutationKInh2 &&
                            errors.salutationKInh2.message
                          }
                          label={formatMessage({
                            id: "contact.input.salutation",
                          })}
                          buttons={[
                            {
                              icon: "icon-male",
                              label: formatMessage({ id: "contact.icon.mr" }),
                              value: "Herr",
                            },
                            {
                              icon: "icon-female",
                              label: formatMessage({ id: "contact.icon.mrs" }),
                              value: "Frau",
                            },
                          ]}
                          onClick={(e, value) => {
                            field.onChange(value);
                          }}
                          {...field}
                        />
                      );
                    }}
                  />
                  <Controller
                    name="firstNameKInh2"
                    control={control}
                    defaultValue={getValues("firstNameKInh2")}
                    render={({ field }) => {
                      return (
                        <InputRow
                          isValid={
                            watch("firstNameKInh2") && !errors.firstNameKInh2
                          }
                          isShowError={!!errors.firstNameKInh2?.message}
                          label={formatMessage({
                            id: "contact.input.firstName",
                          })}
                          isRequired>
                          <Form.Control
                            required
                            {...field}
                            onChange={(e) =>
                              handleInputChange(e.target.value, field.name)
                            }
                          />
                        </InputRow>
                      );
                    }}
                  />
                  <Controller
                    name="lastNameKInh2"
                    control={control}
                    defaultValue={getValues("lastNameKInh2")}
                    render={({ field }) => {
                      return (
                        <InputRow
                          isValid={
                            watch("lastNameKInh2") && !errors.lastNameKInh2
                          }
                          isShowError={!!errors.lastNameKInh2?.message}
                          label={formatMessage({
                            id: "contact.input.lastName",
                          })}
                          isRequired>
                          <Form.Control
                            required
                            {...field}
                            onChange={(e) =>
                              handleInputChange(e.target.value, field.name)
                            }
                          />
                        </InputRow>
                      );
                    }}
                  />
                  <Controller
                    name="birthdayKInh2"
                    control={control}
                    render={({ field: { onChange, onBlur, value, ref } }) => {
                      return (
                        <>
                          <InputRow
                            label={formatMessage({
                              id: "contact.input.birthday",
                            })}
                            isRequired
                            isValid={value}
                            tooltip={formatMessage({
                              id: "common.date.format",
                            })}
                            isShowError={
                              errors.birthdayKInh2 &&
                              errors.birthdayKInh2.message
                            }>
                            <div className="row d-flex align-items-center">
                              <Col md={4}>
                                <DatepickerComponent
                                  isDoB
                                  //value={value}
                                  value={value ? new Date(value) : value}
                                  onChange={(e) => {
                                    onChange(e);
                                    onBlur(e); //TODO: Trick for validate (mode: "onBlur")
                                  }}
                                  onBlur={onBlur}
                                />
                              </Col>
                            </div>
                          </InputRow>
                        </>
                      );
                    }}
                  />
                  <Controller
                    name="nationalityKInh2"
                    isClearable
                    control={control}
                    render={({ field }) => {
                      return (
                        <InputRow
                          isShowError={
                            errors.nationality && errors.nationality.message
                          }
                          label={formatMessage({
                            id: "contact.input.nationality",
                          })}
                          isRequired
                          isValid={field.value}>
                          <Select
                            placeholder={formatMessage({
                              id: "common.date.year",
                            })}
                            {...field}
                            isSearchable
                            options={countries.map((e) =>
                              e.value === ""
                                ? {
                                    value: e.value,
                                    label: e.label,
                                    isDisabled: true,
                                  }
                                : e
                            )}
                            styles={CUSTOM_STYLE}
                          />
                        </InputRow>
                      );
                    }}
                  />
                  <InputRow
                    label={
                      <>
                        {/* <FormattedMessage
                          id="contact.input.street"
                        /> */}
                        <FormattedMessage id="data.input.addressStreet" />
                        <sup class="required"> *</sup>
                        {" / "}
                        <FormattedMessage id="data.input.addressNumber" />
                        <sup class="required"> *</sup>
                        {" / "}
                        <FormattedMessage id="data.input.addressDoor" />
                      </>
                    }
                    isValid={
                      watch("streetKInh2") &&
                      watch("numberKInh2") &&
                      !errors.streetKInh2 &&
                      !errors.numberKInh2
                    }
                    isShowError={
                      errors.streetKInh2
                        ? errors.streetKInh2.message
                        : errors.numberKInh2
                        ? errors.numberKInh2.message
                        : null
                    }>
                    <Row>
                      <Col md={8} sm={8} xs={8}>
                        <Controller
                          name="streetKInh2"
                          control={control}
                          defaultValue={getValues("streetKInh2")}
                          render={({ field }) => {
                            return (
                              <Form.Control
                                className="input-shadow"
                                required
                                {...field}
                                onChange={(event) => {
                                  setValue("streetKInh2", event.target.value);
                                }}
                                onBlur={async () => {
                                  field.onBlur();
                                }}
                              />
                            );
                          }}
                        />
                      </Col>
                      <Col md={2} sm={2} xs={2}>
                        <Controller
                          name="numberKInh2"
                          control={control}
                          defaultValue={getValues("numberKInh2")}
                          render={({ field }) => {
                            return (
                              <Form.Control
                                className="input-shadow"
                                required
                                {...field}
                                onChange={(event) => {
                                  setValue("numberKInh2", event.target.value);
                                }}
                                onBlur={async () => {
                                  field.onBlur();
                                }}
                              />
                            );
                          }}
                        />
                      </Col>
                      <Col md={2} sm={2} xs={2}>
                        <Controller
                          name="doorNumberKInh2"
                          control={control}
                          defaultValue={getValues("doorNumberKInh2")}
                          render={({ field }) => {
                            return (
                              <Form.Control
                                className="input-shadow"
                                {...field}
                                onChange={(event) => {
                                  setValue(
                                    "doorNumberKInh2",
                                    event.target.value
                                  );
                                }}
                                onBlur={async () => {
                                  field.onBlur();
                                }}
                              />
                            );
                          }}
                        />
                      </Col>
                    </Row>
                    <Row></Row>
                  </InputRow>
                  <InputRow
                    label={formatMessage({ id: "contact.input.postcode" })}
                    isRequired
                    isValid={
                      watch("postcodeKInh2") &&
                      watch("placeKInh2") &&
                      !errors.postcodeKInh2 &&
                      !errors.placeKInh2
                    }
                    isShowError={
                      errors.postcodeKInh2
                        ? errors.postcodeKInh2.message
                        : errors.placeKInh2
                        ? errors.placeKInh2.message
                        : null
                    }>
                    <Row>
                      <Col md={4} xs={4} sm={4}>
                        <Controller
                          name="postcodeKInh2"
                          control={control}
                          defaultValue={getValues("postcodeKInh2")}
                          render={({ field }) => {
                            return (
                              <Form.Control
                                inputMode="numeric"
                                className="input-shadow"
                                required
                                {...field}
                                onChange={(event) => {
                                  event.preventDefault();
                                  if (!_.isNaN(Number(event.target.value))) {
                                    setValue(
                                      "postcodeKInh2",
                                      event.target.value
                                    );
                                  }
                                }}
                                onBlur={async () => {
                                  field.onBlur();
                                }}
                              />
                            );
                          }}
                        />
                      </Col>
                      <Col md={8} xs={8} sm={8}>
                        <Controller
                          name="placeKInh2"
                          control={control}
                          defaultValue={getValues("placeKInh2")}
                          render={({ field }) => {
                            return (
                              <Form.Control
                                className="input-shadow"
                                required
                                {...field}
                              />
                            );
                          }}
                        />
                      </Col>
                    </Row>
                    <Row></Row>
                  </InputRow>
                  <Controller
                    name="countryKInh2"
                    isClearable
                    control={control}
                    render={({ field }) => {
                      return (
                        <InputRow
                          isShowError={
                            errors.countryKInh2 && errors.countryKInh2.message
                          }
                          label={formatMessage({ id: "contact.input.country" })}
                          isRequired
                          isValid={field.value}>
                          <Select
                            placeholder={formatMessage({
                              id: "common.date.year",
                            })}
                            {...field}
                            isSearchable
                            options={countries.map((e) =>
                              e.value === ""
                                ? {
                                    value: e.value,
                                    label: e.label,
                                    isDisabled: true,
                                  }
                                : e
                            )}
                            styles={CUSTOM_STYLE}
                          />
                        </InputRow>
                      );
                    }}
                  />
                </>
              )}
            </>
          )}
        </FormBlock>
      </Layout>
    </>
  );
}
