import React from "react";
import { Form, Row, Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import classNames from "classnames";
import { useMediaQuery } from "react-responsive";
import { colors } from "../../themes/colors";

const InputRow = ({
  label,
  isRequired,
  isValid,
  children,
  tooltip,
  isLabelEnd,
  isInline = true,
  isShowError = false,
  isIconNotInlineLabel = false,
  isShowStatus = true,
}) => {
  const isDesktop = useMediaQuery({ minWidth: 992 });
  // const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  const isMobile = useMediaQuery({ maxWidth: 767 });

  return (
    <>
      <style>{`
        p {
          padding: 0;
          margin: 0
        }
        .ic {
          color: ${colors.gray4}
        }
        .required {
          color: ${colors.primary};
        }
        .success {
          color: ${colors.success};
        }
        .input-row-label {
          color: #1C1F26;
          margin-right: 5px;
          font-weight: 500;
        }
        .input-row-label-mobile {
          padding: 0;
        }
      `}</style>
      <Form.Group
        as={Row}
        className={`justify-content-between ${isMobile ? "mb-2" : "mt-4"}`}>
        <Form.Label
          column
          lg={4}
          xs={12}
          className={classNames("d-flex", {
            "align-items-end": isLabelEnd,
            "align-items-center": !isLabelEnd,
            "justify-content-between": isDesktop,
            "input-row-label-mobile": isMobile && !label,
          })}>
          <Col md={12} xs={11} className="p-0 h-100 d-flex">
            {isInline && (
              <p className="input-row-label">
                {label} {isRequired && <sup className="required">*</sup>}
              </p>
            )}
            {tooltip && (
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id="leasingrate-tooltip-top">{tooltip}</Tooltip>
                }>
                <span className="icon-info ic">
                  <span className="path1"></span>
                  <span className="path2"></span>
                </span>
              </OverlayTrigger>
            )}
          </Col>
          {isMobile && !isIconNotInlineLabel && isShowStatus ? (
            <Col md={0} xs={1} className="p-0 h-100  d-flex">
              {!!isValid ? (
                <span className="icon-check-circle success"></span>
              ) : isShowError ? (
                <span
                  className="icon-cancel-circle"
                  style={{ color: "red" }}></span>
              ) : null}
            </Col>
          ) : (
            <Col md={0} xs={1} className="p-0 h-100  d-flex"></Col>
          )}
        </Form.Label>
        <Col
          lg={7}
          md={11}
          sm={isIconNotInlineLabel ? 10 : 12}
          xs={isIconNotInlineLabel ? 10 : 12}>
          {!isInline && (
            <p className="input-row-label mb-1">
              {label} {isRequired && <sup className="required">*</sup>}
            </p>
          )}
          {children}
        </Col>
        {(!isMobile || isIconNotInlineLabel) && isShowStatus ? (
          <Col lg={1} md={1} sm={2} xs={2} className="align-self-center ">
            {!!isValid ? (
              <span className="icon-check-circle success"></span>
            ) : isShowError ? (
              <span
                className="icon-cancel-circle"
                style={{ color: "red" }}></span>
            ) : null}
          </Col>
        ) : (
          <Col lg={1} md={1} sm={2} xs={2} className="align-self-center "></Col>
        )}
      </Form.Group>
    </>
  );
};

export default InputRow;
