import React from "react";
import Footer from "../../../components/Footer/Footer";
import { useIntl } from "react-intl";
import "../leasingPublic.css";

const FooterView = ({ refProp }) => {
  const { formatMessage } = useIntl();

  return (
    <div ref={refProp}>
      <Footer
        telephone={formatMessage({ id: "landing.text.phone" })} //"+43 1 92805960"
        email="welcome.at@leaseteq.com"
        adresse="Am Belvedere 10, Building QBC 2, 1100 Wien, Österreich"
        linked="https://goo.gl/maps/qsQECYs8CERAcLVYA"
      />
    </div>
  );
};

export default FooterView;
