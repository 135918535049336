import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import "./footer.css";
import Logo from "../../assets/logo.png";
import { useMediaQuery } from "react-responsive";
import LinkedLogo from "../../assets/LinkedInlogo-100x25.png";
import { RESPONSIVE } from "constant";
import useWindowSize from "hooks/useWindowSize";
import { getGridSystemForFooter } from "screen/LeasingFormPublic/leasing-engine";
import { useLocale } from "context/locale";
import { getLink } from "screen/LeasingFormPublic/links-configuration";
import { useIntl } from "react-intl";

function Footer({ telephone, email, adresse, linked }) {
  const isSmallSize = useMediaQuery({ maxWidth: RESPONSIVE.MAX_WIDTH_SMALL });
  const [width, height] = useWindowSize();
  const { locale } = useLocale();
  const { formatMessage } = useIntl();
  return (
    <div
      className="footer-wrap"
      style={{ gridTemplateColumns: getGridSystemForFooter(width) }}>
      <div className="space"></div>
      <div className="col-first">
        <Image src={Logo} fluid alt="logo" className="logo-footer" />
        {!isSmallSize && (
          <span className="copyright-wrapper">
            Ⓒ LeaseTeq AG | {new Date().getFullYear()}
          </span>
        )}
      </div>
      <div className="space"></div>
      <div className="col-second">
        <div className="main-info-footer">
          <a href={`tel:${telephone}`}>{telephone}</a>
          <div className="holder-col-link"></div>
          <a href={`mailto:${email}`}>{email}</a>
          <div className="holder-col-link"></div>
          <a href={`http://maps.google.com/?q=${adresse}`} target="blank">
            {adresse}
          </a>
          <div className="holder-col-last"></div>
        </div>
        <a href={linked} target="blank" className="linked-wrapper">
          <Image src={LinkedLogo} alt="LinkedLogo" />
        </a>
      </div>
      <div className="col-third font-weight-bold">
        {isSmallSize && <div className="line-horizontal-footer"></div>}
        <div className="privacy-wrapper">
          <a href={getLink(locale, "ABOUT_US")}>
            {formatMessage({ id: "publicleasing.text.about-us" })}
          </a>
          <div className="holder-col-link"></div>
          <a href={getLink(locale, "IMPRINT")}>
            {formatMessage({ id: "publicleasing.text.imprint" })}
          </a>
          <div className="holder-col-link"></div>
          <a href={getLink(locale, "PRIVACY_POLICY")}>
            {formatMessage({ id: "publicleasing.text.data-privacy" })}
          </a>
        </div>
        {isSmallSize && (
          <div className="mt-5 mb-5">
            <span className="copyright-mobile-wrapper">
              Ⓒ LeaseTeq AG | {new Date().getFullYear()}
            </span>
          </div>
        )}
      </div>
    </div>
  );
}

export default Footer;
