import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { Form } from "react-bootstrap";

import "./input.css";

export default function InputComponent({
  placeholder,
  disabled,
  value,
  onChange,
  onBlur,
  onFocus,
  isArea,
  className,
  size,
  isButton,
}) {
  const [localValue, setLocalValue] = useState(value);
  const _onChange = useCallback(
    (event) => {
      setLocalValue(event.target.value);
      if (onChange) {
        onChange(event);
        setLocalValue(event.target.value);
      }
    },
    [onChange]
  );
  /**
   * Description placeholder
   * @date 21/04/2023 - 10:15:47
   *  previoous state of use Effect was using "value" as trigger which was causing issues such as not updating value on UI
   *  So lets take the case of eliminating all digits on vorname, it would do the deletion in background when a new digit was added
   *  resulting in no actual change in "value"  becuase of which it would still show digits on Ui while background data had no digits, it was not re rendering the useEffect below
   * we changed triggger from "value" to "localValue" which works perfect in this case here,
   * if in future it doesnt work then you can maybe make a prop based solution where useEffect trigger changes based on a prop true or false(did not try it but can be tried later)
   * useEffect(() => {
   *   setLocalValue(value);
   *  }, [value]);
   *  CL: removing this "fix" as it broke the display of monthly rate
   */

  useEffect(() => {
    setLocalValue(value);
  }, [value]);
  return (
    <Form.Control
      size={`${size}`}
      placeholder={placeholder}
      disabled={disabled}
      value={localValue}
      onChange={(event) => _onChange(event)}
      onBlur={onBlur}
      onFocus={onFocus}
      as={isArea ? "textarea" : isButton ? "button" : "input"}
      className={isArea ? "textarea" : className ? className : "input-shadow"}>
      {isButton ? localValue : null}
    </Form.Control>
  );
}

InputComponent.defaultProps = {
  disabled: false,
  isArea: false,
  isButton: false,
};
InputComponent.propTypes = {
  isButton: PropTypes.bool,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  style: PropTypes.instanceOf(Object),
};
